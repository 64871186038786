import PropTypes from 'prop-types';
import { useState ,useEffect} from 'react';

import { MenuItem, IconButton ,ListItemIcon,ListItemText} from '@mui/material';
import {  useSelector } from '../../../../redux/store';

// components
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

UserProjTimeline.propTypes = {
  onPause: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserProjTimeline(props) {

  const {onEdit,onDelete } = props;

  const [open, setOpen] = useState(null);
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} sx={{color:"#000000"}} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >  
        <MenuItem value="" onClick={() => {onEdit();handleClose();}}  >
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:edit-outline'} width={20} height={20} sx={{color:"#000000"}} />
          </ListItemIcon>
          <ListItemText primary="Edit " />
        </MenuItem>
        {/* <MenuItem value=""  onClick={() => {onPreview();handleClose();}}>
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:eye-outline'} width={20} height={20} sx={{color:"#000000"}} />
          </ListItemIcon>
          <ListItemText primary="Preview" />
        </MenuItem> */}
        <MenuItem value=""  onClick={() => {onDelete();handleClose();}} >
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:trash-2-outline'} width={20} height={20} sx={{color:"#000000"}} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
       
      </MenuPopover>
    </>
  );
}
