import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getMyStaffList: [],
  getMyRoleList: [],
  getMyStaffDelete: [],
  getMyStaffCreate: [],
  getProjectNewsUpdate: [],
  getMyProjectSetting: [],
  getManageProjectResp: [],
  getVerifyProjOtpResp: [],
  SendProjOtpResp: [],
  ReSendProjOtpResp: [],
  saveNewsUpdate: [],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'Project Data',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    getStaffListSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffList = action.payload;
    },

    getRoleListSucc(state, action) {
      state.isLoading = false;
      state.getMyRoleList = action.payload;
    },

    getStaffDeleteSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffDelete = action.payload;
    },

    saveNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.saveNewsUpdate = action.payload;
    },

    getProjectNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.getProjectNewsUpdate = action.payload;
    },

    getProjectSettingUpdateSucc(state, action) {
      state.isLoading = false;
      state.getMyProjectSetting = action.payload;
    },

    getStaffCreateSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffCreate = action.payload;
    },
    getProjectEmployerSettingSucc(state, action) {
      state.isLoading = false;
      state.getSettingEmployerData = action.payload;
    },

    getManageProjectSucc(state, action) {
      state.isLoading = false;
      state.getManageProjectResp = action.payload;
    },

    getVerifyOtpProSucc(state, action) {
      state.isLoading = false;
      state.getVerifyProjOtpResp = action.payload;
    },

    getSendProjOtpSucc(state, action) {
      state.isLoading = false;
      state.SendProjOtpResp = action.payload;
    },

    getReSendProjOtpSucc(state, action) {
      state.isLoading = false;
      state.ReSendProjOtpResp = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getStaffList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_members/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getStaffListSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function getRoleList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_roles/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getRoleListSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getDeleteStaff(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_team_member/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getStaffDeleteSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_project_news_update/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProjectNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_newsupdate/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getProjectSetting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_settings/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectSettingUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProjectEmployerSetting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_settings/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectEmployerSettingSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCreateStaff(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_team_member/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getStaffCreateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getManageProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_manage_project/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getManageProjectSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCreateTeam(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getStaffCreateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProjectSettingUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectSettingUpdateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVerifyOtpProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/verify_project_otp/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getVerifyOtpProSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearVerifyProjectSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getVerifyOtpProSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function SendProjectOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_project_otp/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSendProjOtpSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearSendProjOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSendProjOtpSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ReSendProjectOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_project_otp/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getReSendProjOtpSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearReSendProjOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getReSendProjOtpSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


