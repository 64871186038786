import { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';

import BlurImg from '../images/blurImg1.webp';

// ----------------------------------------------------------------------

Image.propTypes = {
  disabledEffect: PropTypes.bool,
  effect: PropTypes.string,
  ratio: PropTypes.oneOf(['4/3', '4/5', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1']),
  sx: PropTypes.object,
};

Image.defaultProps = {
  ratio: '1/1',
};

export default function Image({ ratio, disabledEffect = false, effect = 'blur', sx, ...other }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  console.log(imageLoaded, 'imageLoaded', ratio);


  const paddingTop = ratio ? getPaddingTop(ratio) : '100%';

  return (
    <Box
      component="span"
      sx={{
        width: 1,
        lineHeight: 0,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        pt: paddingTop,
        ...sx,
      }}
    >
      <Box
        component="span"
        sx={{
          width: '100%',
          height: '100%',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundImage: `url(${BlurImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: imageLoaded ? 'none' : 'blur(20px)',
          transition: 'filter 0.3s ease-in-out',
        }}
      />
      <LazyLoadImage
        wrapperClassName="wrapper newwrapper"
        src={other.src}
        alt={other.alt}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          opacity: imageLoaded ? 1 : 0, // Show image only when loaded
          transition: 'opacity 0.3s ease-in-out',
        }}
        onLoad={handleImageLoad}
        {...other}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

function getPaddingTop(ratio = '1/1') {
  return {
    '4/3': 'calc(75%)',
    '4/5': 'calc(125%)',
    '3/4': 'calc(133.33%)',
    '6/4': 'calc(66.67%)',
    '4/6': 'calc(150%)',
    '16/9': 'calc(56.25%)',
    '9/16': 'calc(177.78%)',
    '21/9': 'calc(42.86%)',
    '9/21': 'calc(233.33%)',
    '1/1': '100%',
  }[ratio];
}
