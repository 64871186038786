import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Icon } from '@iconify/react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, Button, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { PATH_DASHBOARD, PATH_PROJECTS } from '../../../routes/paths';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
import Iconify from '../../../components/Iconify';

import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [ProjectId, setProjectId] = useState(false);
  const url = window.location.href.split('/');
  const ProjectId = url[4]; // Templateid
  const TempId = window.localStorage.getItem('Templateid');
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleGotoTeam = () => {
    if (TempId && TempId !== undefined && TempId !== '') {
      console.log(TempId, 'hereCome', 2)
      if (TempId && TempId === '1') {
        navigate(`/projects/${ProjectId}/team/team_quick/1`, { replace: true });
      }
      if (TempId && TempId === '2') {
        navigate(`/projects/${ProjectId}/team/team_details/16/crew`, { replace: true });
        // navigate(`/projects/${ProjectId}/team/team_details/16/Crew`, { replace: true });
      }
    } else {
      navigate(PATH_DASHBOARD.project.projects, { replace: true });
    }
  };

  console.log(ProjectId, 'ProjectId78968768', TempId)

  const renderContent = (
    <Scrollbar
      className='sideBar'
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <NavSectionVertical  navConfig={navConfig} isCollapse={isCollapse} /> */}

      <Box sx={{ flexGrow: 1, pt: 4, fontSize: 24, color: '#fff' }} >
        <Stack direction="row" alignItems="center" justifyContent="center"  >
          <IconButton onClick={() => handleGotoTeam()} >
            <Icon icon="clarity:home-solid" style={{ fontSize: "20px", color: "#85a094 !important", fontWeight: 600 }} />
          </IconButton>
          {/* {TempId && TempId !== '' ?
            <>
              {TempId && TempId === 1 ?
                <Button
                  component={RouterLink}
                  to={`/projects/${ProjectId}/team/team_quick/1`}
                  // sx={{color:'#85a094 !important'}}
                  // type='button'
                  // onClick={(e)=>handleGotoTeam()}
                  startIcon={<Iconify icon={'clarity:home-solid'} />}
                />
                :
                null
              }
              {TempId && TempId === 2 ?
                <Button
                  component={RouterLink}
                  to={`/projects/${ProjectId}/team/team_details/16/Crew`}
                  // sx={{color:'#85a094 !important'}}
                  // type='button'
                  // onClick={(e)=>handleGotoTeam()}
                  startIcon={<Iconify icon={'clarity:home-solid'} />}
                />
                :
                null
              }
            </>
            :
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.project.projects}
              startIcon={<Iconify icon={'clarity:home-solid'} />}
            />
          } */}
        </Stack>
      </Box>

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              borderRightStyle: 'solid',
              top: 80,
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
