import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  listOfCandidate: [],
  slugOfCandidate: [],
  slugDetailsCandidate: [],
  candidateScheduleData: [],
  candidateNoteData: [],
  candidateSendMessageData: [],
  candidateStatusNotesData: [],
  candidateHistoryData: [],
  candidateMessageData: [],
  deletecandidateMessageData: [],
  sentMessageData: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET EVENTS
    getCandidateListItems(state, action) {
      state.isLoading = false;
      state.listOfCandidate = action.payload;
    },
    getCandidateSlugRes(state, action) {
      state.isLoading = false;
      state.slugOfCandidate = action.payload;
    },
    dataSlugProfile(state, action) {
      state.isLoading = false;
      state.slugDetailsCandidate = action.payload;
    },
    candidateScheduleRes(state, action) {
      state.isLoading = false;
      state.candidateScheduleData = action.payload;
    },
    candidateNoteRes(state, action) {
      state.isLoading = false;
      state.candidateNoteData = action.payload;
    },
    candidateSendMessageRes(state, action) {
      state.isLoading = false;
      state.candidateSendMessageData = action.payload;
    },
    getCandidateStatusNotesRes(state, action) {
      state.isLoading = false;
      state.candidateStatusNotesData = action.payload;
    },
    getJobCandidateHistoryListSucc(state, action) {
      state.isLoading = false;
      state.candidateHistoryData = action.payload;
    },

    getJobCandidateMessageListSucc(state, action) {
      state.isLoading = false;
      state.candidateMessageData = action.payload;
    },

    deleteCandidateMessageListSucc(state, action) {
      state.isLoading = false;
      state.deletecandidateMessageData = action.payload;
    },

    SendReplyToPostsucc(state, action) {
      state.isLoading = false;
      state.sentMessageData = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getCadidatesList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_candidates/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCandidateListItems(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCadidatesSlug(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_slug/', data);
      console.log(response, 'fdgdgf5fdgfdg');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCandidateSlugRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function CandidateScheduleInterview(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/schedule_interview/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.candidateScheduleRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function CandidateNotes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_note/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.candidateNoteRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function CandidateSendMessages(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_message/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.candidateSendMessageRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCadidatesStatusNotes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/candidate_status_notes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCandidateStatusNotesRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getJobCandidateHistoryList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_job_history/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobCandidateHistoryListSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getJobCandidateMessageList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_talent_job_messages/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobCandidateMessageListSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function deleteCandidateMessageList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteCandidateMessageListSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function SendReplyToPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_message/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SendReplyToPostsucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function getSlugProfileDetails(data) {


//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const config = {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           type: 'talent',
//           browser_details: navigator.userAgent,
//         })
//       }

//       // const fetchResponse = await fetch(`${Talent_Profile_URL}${data && data.slug}`, config);

//       // const fetchResponse = await fetch(`https://api.talentkind.in:9000/api/talent/view_profile/${data && data.slug}`, config);

//       if (process.env.NODE_ENV !== "Production") {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}view_profile/${data && data.slug}`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.dataSlugProfile(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//       } else {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}view_profile/${data && data.slug}`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.dataSlugProfile(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//       }

//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     };
//   }
// }


export function getSlugProfileDetails(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'talent',
          browser_details: navigator.userAgent,
        }),
      };

      const Talent_Profile_view_URL = process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL
        : process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;

      console.log(Talent_Profile_view_URL, 'Talent_Profile_view_URL', process.env.REACT_APP_NODE_ENV === 'development', process.env.REACT_APP_NODE_ENV,);
      console.log(process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL, 'Talent_Profile_view_URL 2', process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE);

      const fetchResponse = await fetch(`${Talent_Profile_view_URL}view_profile/${data?.slug}`, config);
      const data1 = await fetchResponse.json();

      if (data1 && data1.status === 200) {
        dispatch(slice.actions.dataSlugProfile(data1));
      } else {
        dispatch(slice.actions.hasError(data1));
      }
    } catch (error) {
      const errorMessage = error?.status ? error : { status: "502", message: "Something went wrong" };
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}

export function CleardeleteData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteCandidateMessageListSucc([]));
      dispatch(slice.actions.SendReplyToPostsucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSlugProfileDetails() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.dataSlugProfile([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearcandidateNote() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.candidateNoteRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCandidateStatusNotes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCandidateStatusNotesRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearcandidateSchedules() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.candidateScheduleRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
