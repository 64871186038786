import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    getPayoutDataResp: [],
    getDetailedPayoutDataResp: [],
    getWalletCouponResp: [],
    getApplyWalletCouponResp: [],
    getAddWalletAmountResp: [],
    getAddPaymentWalletAmountResp: [],
    SavePaymentWalletResp: [],
    getEmployerWalletResp: [],
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EVENTS   
        getPayoutDataSucc(state, action) {
            state.isLoading = false;
            state.getPayoutDataResp = action.payload;
        },
        getDetailedPayoutDataSucc(state, action) {
            state.isLoading = false;
            state.getDetailedPayoutDataResp = action.payload;
        },
        getWalletCouponSucc(state, action) {
            state.isLoading = false;
            state.getWalletCouponResp = action.payload;
        },
        getApplyWalletCouponSucc(state, action) {
            state.isLoading = false;
            state.getApplyWalletCouponResp = action.payload;
        },
        getAddWalletAmountSucc(state, action) {
            state.isLoading = false;
            state.getAddWalletAmountResp = action.payload;
        },
        getAddPaymentWalletAmountSucc(state, action) {
            state.isLoading = false;
            state.getAddPaymentWalletAmountResp = action.payload;
        },
        SavePaymentWalletSucc(state, action) {
            state.isLoading = false;
            state.SavePaymentWalletResp = action.payload;
        },
        getEmployerWalletSucc(state, action) {
            state.isLoading = false;
            state.getEmployerWalletResp = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getPayoutData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_employer_payout/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getPayoutDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function CleargetPayoutData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getPayoutDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getDetailedPayoutData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_job_deduction_amount/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getDetailedPayoutDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function CleargetDetailedPayoutData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getDetailedPayoutDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getWalletCouponData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/get_wallet_coupons/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getWalletCouponSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}


export function ClearGetWalletCouponData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getWalletCouponSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ApplyWalletCoupon(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/apply_wallet_coupon/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getApplyWalletCouponSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}


export function ClearApplyWalletCoupon(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getApplyWalletCouponSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function AddWalletAmount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/add_wallet_amount/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getAddWalletAmountSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function ClearAddWalletAmount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getAddWalletAmountSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function AddPaymentWalletAmount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/add_wallet_amount/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getAddPaymentWalletAmountSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function ClearAddPaymentWalletAmount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getAddPaymentWalletAmountSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function SavePaymentWallet(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/save_payment/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.SavePaymentWalletSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function ClearSavePaymentWallet() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.SavePaymentWalletSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEmployerWallet(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_employer_wallet/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getEmployerWalletSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

