import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Grid, CardContent } from '@mui/material';
// import { AuthSlider } from 'src/components/carousel/index';
import { AuthSlider } from '../../components/carousel/index';

// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/LoginLogo';
import Logo1 from '../../images/talentsmalllogo.png'

import LoginImageBlock from '../../components/LoginImage';
// import Image from '../../components/Image';

// sections
import { LoginForm } from '../../sections/auth/login';
import SocialMedia from './SocialMediaLogin';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 0, 0),
  background: '#f8f8f8',
  borderRadius: '0',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '50%',
  margin: 'auto',
  top: '0',
  bottom: '0',
  height: 580,
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0 0 15px #eee',
  border: '2px solid #eceef1',
  borderRadius: '15px',
  padding: theme.spacing(3),
  left: '0',
  right: '0',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
  }, [width])



  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <main >
        <Container maxWidth="xxl" style={{ padding: "0rem", height: "100%" }} id='LoginContainer'>
          <Box style={{ position: "relative" }} id='LoginContainerBox ' sx={{ pb: { xs: '15rem', sm: 0 }, height: { xs: `calc(100% + 15rem)`, sm: '100%' } }}>
            <AuthSlider />
            <Box id="overlay" >
              <header id="registerPage" style={{ width:"fit-content"}}>
                <Container maxWidth="xxl" style={{ padding: "1rem" }}>
                  <Logo />
                </Container>
              </header>
              <Grid container justifyContent="center" sx={{ height: { xs: 'auto', sm: `calc(100% - 10rem)` }, }}>
                <Grid item xs={12} style={{ height: "100%" }} className='Z_index'>
                  <Box p={2} style={{ height: "100%" }}>
                    <Grid container sx={{ height: "100%", justifyContent: { sm: 'center', md: 'space-between' }, alignItems: 'center' }}>
                      {
                        width > 992 && <Grid item md={3.5}>&nbsp; </Grid>}

                      <Grid item xs={12} sm={8.6} md={6.1} lg={5} xl={4} xxl={3.2}>
                        <Box>
                          <Card>
                            <CardContent style={{ padding: '2rem 1.7rem', paddingTop: '3.5rem ' }}>
                              <Typography variant="h3" gutterBottom>Sign in</Typography>
                              <LoginForm />
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <footer className='AuthRegisterFooter'>
                <Grid container >
                  <Grid item xs={12}>
                    <Box mt={{ xs: 1, sm: 0 }} mb={2} id="footerMenus">
                      <ul style={{ flexWrap: 'wrap', alignItems: 'end' }}>
                        <li><a href="https://talentkind.com"><img src={Logo1} alt="logo" style={{ width: '45px' }} /></a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href="https://talentkind.com/cookie.html">Cookie Policy</a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href="https://talentkind.com/privacy.html">| Privacy Policy</a></li>
                        <li><a target='_blank' rel="noopener noreferrer" href="https://talentkind.com/terms.html">| Terms of Service</a></li>
                        <li>| Copyright &copy; {new Date().getFullYear()} TalentKind Solutions LLP</li>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </footer>
            </Box>
          </Box>
        </Container>
      </main>

    </Page>
  );
}
