import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  skillSetData: [],
  skillSetlistData: [],
  PostSkillSetData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getSkillSetSucc(state, action) {
      state.isLoading = false;
      state.skillSetData = action.payload;
    },
    // GET Skills
    getSkillSetListSucc(state, action) {
      state.isLoading = false;
      state.skillSetlistData = action.payload;
    },
    postSkillSetSucc(state, action) {
        state.isLoading = false;
        state.PostSkillSetData = action.payload;
      },
   
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getSkillSetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_employer_type/');
      dispatch(slice.actions.getSkillSetSucc(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getSkillSet(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     const tokenVal = window.localStorage.getItem("accessToken")
//     try {
//       const response = await axios.get('/get_skillset/');
//       dispatch(slice.actions.getSkillSetListSucc(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getSkillSet(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/dev_get_skillset/',data);
        dispatch(slice.actions.getSkillSetListSucc(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
};
}

export function SaveSkilSetData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/save_employer_type/',data);
        dispatch(slice.actions.postSkillSetSucc(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
};
}

export function ClearSkilSetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.postSkillSetSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearSkillList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSkillSetListSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}