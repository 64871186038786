// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "";
const ROOTS_DASHBOARD = "";
const ROOTS_PROJECTS = "/projects";
const ROOTS_JOBS = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  resetConfirmPassword: path(ROOTS_AUTH, "/reset-confirm-password"),
  verify: path(ROOTS_AUTH, "/verify"),
  userDone: path(ROOTS_AUTH, "/user-done"),
};
export const PATH_PROFILE = {
  // locationsDetails: ROOTS_DASHBOARD,
  locationsDetails: {
    root: path(ROOTS_DASHBOARD, ""),
    services: path(ROOTS_DASHBOARD, "/services"),
    projects: path(ROOTS_DASHBOARD, "/projects"),
    jobs: path(ROOTS_DASHBOARD, "/jobs"),
    gallery: path(ROOTS_DASHBOARD, "/gallery"),
    new_updates: path(ROOTS_DASHBOARD, "/new_updates"),
    contact: path(ROOTS_DASHBOARD, "/contact"),
  },
  projectsDetails: ROOTS_DASHBOARD,
  prevProj: path(ROOTS_AUTH, "/project-preview"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    profile: path(ROOTS_DASHBOARD, "/profile"),
  },
  app: path(ROOTS_DASHBOARD, "/dashboard"),
  // setting: path(ROOTS_DASHBOARD, '/setting'),
  myContact: path(ROOTS_DASHBOARD, "/contacts"),
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  activityLog: path(ROOTS_DASHBOARD, "/activity-log"),

  // resumes: path(ROOTS_DASHBOARD, '/resumes'),
  // coverLetter: path(ROOTS_DASHBOARD, '/coverLetter'),
  inBox: {
    root: path(ROOTS_DASHBOARD, "/inbox"),
    talent: path(ROOTS_DASHBOARD, "/inbox/talent"),
    institution: path(ROOTS_DASHBOARD, "/inbox/talent/institution"),
    notification: path(ROOTS_DASHBOARD, "/inbox/talent/notification"),
    enquiry: path(ROOTS_DASHBOARD, "/inbox/talent/enquiry"),
  },
  subscription: path(ROOTS_DASHBOARD, "/subscription"),
  // website: path(ROOTS_DASHBOARD, '/website'),
  networking: path(ROOTS_DASHBOARD, "/networking"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  jobs: {
    root: path(ROOTS_DASHBOARD, "/jobs"),
    postings: path(ROOTS_DASHBOARD, "/jobs/postings/company"),
    projectjob: path(ROOTS_DASHBOARD, "/jobs/postings/project"),
    archivedjob: path(ROOTS_DASHBOARD, "/jobs/postings/archived"),
    create: path(ROOTS_DASHBOARD, "/jobs/create"),
  },
  website: {
    root: path(ROOTS_DASHBOARD, "/website"),
    about: path(ROOTS_DASHBOARD, "/website/aboutus"),
    banners: path(ROOTS_DASHBOARD, "/website/banners"),
    services: path(ROOTS_DASHBOARD, "/website/services"),
    projects: path(ROOTS_DASHBOARD, "/website/projects"),
    theme: path(ROOTS_DASHBOARD, "/website/theme"),
    setting: path(ROOTS_DASHBOARD, "/website/setting"),
    enquiry: path(ROOTS_DASHBOARD, "/website/enquiry"),
    socialmedia: path(ROOTS_DASHBOARD, "/website/socialmedia"),
    gallery: path(ROOTS_DASHBOARD, "/website/gallery"),
    galleryImageUpload: path(ROOTS_DASHBOARD, "/website/gallery/image-upload"),
    galleryAlbumView: path(ROOTS_DASHBOARD, "/website/gallery/album-view"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/personal-info"),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, "/setting"),
    companyProfile: path(ROOTS_DASHBOARD, "/setting/companyProfile"),
    mea_category: path(ROOTS_DASHBOARD, "/setting/mea_category"),
    account: path(ROOTS_DASHBOARD, "/setting/account"),
    notification: path(ROOTS_DASHBOARD, "/setting/notification"),
    profileVisibility: path(ROOTS_DASHBOARD, "/setting/profileVisibility"),
    manageAccount: path(ROOTS_DASHBOARD, "/setting/manageAccount"),
    deleteAccount: path(ROOTS_DASHBOARD, "/setting/deleteAccount"),
    subscriptionPlans: path(ROOTS_DASHBOARD, "/setting/subscription"),
    // subscriptionPlans: path(ROOTS_DASHBOARD, '/setting/subscription/plans'),
    // subscriptionTable: path(ROOTS_DASHBOARD, '/setting/subscription/subscriptionTable'),
    billingInfo: path(ROOTS_DASHBOARD, "/setting/subscription/billingInfo"),
    invoiceDetails: path(ROOTS_DASHBOARD, "/setting/subscription/invoiceDetails"),
    payment: path(ROOTS_DASHBOARD, "/setting/paymentHistory"),
  },
  wallet: {
    root: path(ROOTS_DASHBOARD, "/wallet"),
    payouts: path(ROOTS_DASHBOARD, "/wallet/payouts"),
    account: path(ROOTS_DASHBOARD, "/wallet/recharge"),
  },
  talent: {
    root: path(ROOTS_DASHBOARD, "/talent"),
    search: path(ROOTS_DASHBOARD, "/talent/search"),
    talentSearch: path(ROOTS_DASHBOARD, "/talent/talentSearch"),
    // talentSearchResult: path(ROOTS_DASHBOARD, '/search/talentSearchResult'),
    talentSearchMain: path(ROOTS_DASHBOARD, "/talent/talentSearchMain"),
  },
  search: {
    root: path(ROOTS_DASHBOARD, "/search"),
    talentSearch: path(ROOTS_DASHBOARD, "/search/talentSearch"),
    talentSearchResult: path(ROOTS_DASHBOARD, "/search/talentSearchResult"),
    talentSearchMain: path(ROOTS_DASHBOARD, "/search/talentSearchMain"),
  },
  connectnetwork: {
    root: path(ROOTS_DASHBOARD, "/network"),
    // followers: path(ROOTS_DASHBOARD, "/network/followers"),
    // following: path(ROOTS_DASHBOARD, "/network/following"),
    mynetwork: path(ROOTS_DASHBOARD, "/network/mynetwork"),
    network: path(ROOTS_DASHBOARD, "/network/network"),
    myinvitation: {
      root: path(ROOTS_DASHBOARD, "/network/myinvitation"),
      link: path(ROOTS_DASHBOARD, "/network/myinvitation/link"),
      email: path(ROOTS_DASHBOARD, "/network/myinvitation/email"),
      bulk: path(ROOTS_DASHBOARD, "/network/myinvitation/bulk"),
      status: path(ROOTS_DASHBOARD, "/network/myinvitation/status"),
    },
    followers: {
      root: path(ROOTS_DASHBOARD, "/network/followers"),
      talents: path(ROOTS_DASHBOARD, "/network/followers/talents"),
      companies: path(ROOTS_DASHBOARD, "/network/followers/companies"),
      institution: path(ROOTS_DASHBOARD, "/network/followers/institution"),
    },
    following: {
      root: path(ROOTS_DASHBOARD, "/network/following"),
      talents: path(ROOTS_DASHBOARD, "/network/following/talents"),
      companies: path(ROOTS_DASHBOARD, "/network/following/companies"),
      institution: path(ROOTS_DASHBOARD, "/network/following/institution"),
    },
  },
  project: {
    root: path(ROOTS_DASHBOARD, "/project"),
    projectDetails: path(ROOTS_DASHBOARD, "/project/project-details"),
    photos: path(ROOTS_DASHBOARD, "/project/photos"),
    videos: path(ROOTS_DASHBOARD, "/project/videos"),
    projectCreate: path(ROOTS_DASHBOARD, "/project/projectcreate"),
    projectView: path(ROOTS_DASHBOARD, "/project/project"),
    projectPreview: path(ROOTS_DASHBOARD, "/project/project-preview"),
    projects: path(ROOTS_DASHBOARD, "/project"),
    // projects: path(ROOTS_DASHBOARD, '/project/list'),

    // teams: path(ROOTS_DASHBOARD, '/project/teams'),
    // createmember: path(ROOTS_DASHBOARD, '/project/createmember'),
    Viewproject: path(ROOTS_DASHBOARD, "/project/project-view"),
  },

  staff: {
    root: path(ROOTS_DASHBOARD, "/staff"),
    teams: path(ROOTS_DASHBOARD, "/staff"),
    createmember: path(ROOTS_DASHBOARD, "/staff/createmember"),
  },

  reports: {
    root: path(ROOTS_DASHBOARD, "/report"),
    activity: path(ROOTS_DASHBOARD, "/report/activity"),
  },
};
export const PATH_PROJECTS = {
  root: path(ROOTS_PROJECTS, "/projects"),
  projects: {
    mainDashboard: path(ROOTS_PROJECTS, "/dashboard"),
    projectDashboard: path(ROOTS_PROJECTS, "/projectdashboard"),
    QuickTemplate: path(ROOTS_PROJECTS, "/team_quick"),
    DetailedTemplate: path(ROOTS_PROJECTS, "/team_details"),
    projectDepartments: path(ROOTS_PROJECTS, "/projectdepartments"),
    newsUpdate: path(ROOTS_PROJECTS, "/newsupdate"),
    remuneration: path(ROOTS_PROJECTS, "/remuneration"),
    remunerationDetailed: path(ROOTS_PROJECTS, "/remuneration-detailed"),
    remunerationInvoice: path(ROOTS_PROJECTS, "/remuneration-invoice"),
    projectSettings: path(ROOTS_PROJECTS, "/projectsettings"),
    budget: path(ROOTS_PROJECTS, "/budget"),
    wages: path(ROOTS_PROJECTS, "/wages"),
    timeLine: path(ROOTS_PROJECTS, "/timeline"),
    reports: path(ROOTS_PROJECTS, "/reports"),
    jobs: path(ROOTS_PROJECTS, "/jobs"),
    teamDashboard: path(ROOTS_PROJECTS, "/teamdashboard"),
    Payments: path(ROOTS_PROJECTS, "/payments"),
    Connect: path(ROOTS_PROJECTS, "/connect"),
    teamReports: path(ROOTS_PROJECTS, "/teamreports"),
    TeamSettings: path(ROOTS_PROJECTS, "/teamsettings"),
  },
};
export const PATH_JOBS = {
  root: path(ROOTS_DASHBOARD, "/jobs"),
  jobs: {
    Dashboard: path(ROOTS_DASHBOARD, "/jobs/Dashboard"),
    Candidates: path(ROOTS_DASHBOARD, "/jobs/Candidates"),
    Messages: path(ROOTS_DASHBOARD, "/jobs/Messages"),
    Interviews: path(ROOTS_DASHBOARD, "/jobs/Interviews"),
    Jobseek: path(ROOTS_DASHBOARD, "/jobs/Jobseek"),
    reports: path(ROOTS_DASHBOARD, "/jobs/reports"),
    activity: path(ROOTS_DASHBOARD, "/jobs/activity"),
    timeline: path(ROOTS_DASHBOARD, "/jobs/timeline"),
    settings: path(ROOTS_DASHBOARD, "/jobs/settings"),
    ViewCandidate: path(ROOTS_DASHBOARD, "/jobs/ViewCandidate"),
    jobCreate: path(ROOTS_DASHBOARD, "/jobs/jobCreate"),
    JobOfficePreview: path(ROOTS_DASHBOARD, "/jobs/JobOfficePreview"),
    jobProjectCreate: path(ROOTS_DASHBOARD, "/jobs/jobProjectCreate"),
    jobProjectEdit: path(ROOTS_DASHBOARD, "/jobs/jobProjectEdit"),
    jobCompanyEdit: path(ROOTS_DASHBOARD, "/jobs/jobCompanyEdit"),
    jobProjectList: path(ROOTS_DASHBOARD, "/jobs/jobProjectList"),
    CastJobPosting: path(ROOTS_DASHBOARD, "/jobs/castJobPosting"),
    CrewJobPosting: path(ROOTS_DASHBOARD, "/jobs/crewJobPosting"),
    ProjectCastJobCreate: path(ROOTS_DASHBOARD, "/jobs/projectcastjobcreate"),
    ProjectCrewJobCreate: path(ROOTS_DASHBOARD, "/jobs/projectcrewjobcreate"),
  },
};
export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
