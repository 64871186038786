import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  profileDetailsResponse: [],
  WebsiteGalleryDetails: [],
  WebsiteJobsDetails: [],
  WebsiteProjectsDetails: [],
  WebsiteServicesDetails: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'Location Details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profileDetailsResponse = action.payload;
    },
    getWebsiteGallerySucc(state, action) {
      state.isLoading = false;
      state.WebsiteGalleryDetails = action.payload;
    },
    getWebsiteJobsSucc(state, action) {
      state.isLoading = false;
      state.WebsiteJobsDetails = action.payload;
    },
    getWebsiteProjectsSucc(state, action) {
      state.isLoading = false;
      state.WebsiteProjectsDetails = action.payload;
    },
    getWebsiteServicesSucc(state, action) {
      state.isLoading = false;
      state.WebsiteServicesDetails = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileDetails(ticketId, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/view_profile/${ticketId}`, data);
      dispatch(slice.actions.getProfileSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getWebsiteGallery(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_gallery/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteGallerySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function getWebsiteJobs(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_web_jobs/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getWebsiteProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_web_projects/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteProjectsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getWebsiteServices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_web_services/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteServicesSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

