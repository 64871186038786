import useAuth from '../hooks/useAuth';
// utils

export default function DateFormat({ date }) {

  const { user } = useAuth();

  return (
    <>{date
      ? new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, ' ')
      : 'NA'}</>
  );
}
// ==== 1 ====
// {new Intl.DateTimeFormat('en-GB', {
//   month: 'long',
//   day: '2-digit',
//   year: 'numeric',
// }).format(new Date(date))}

// ==== 2 ====
// new Date(date).toLocaleDateString('en-us', {
//   year: 'numeric',
//   month: 'short',
//   day: 'numeric',
// })