// routes
import { useEffect, useState } from 'react';
import { PATH_DASHBOARD, PATH_PROJECTS } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { useSelector } from '../../../redux/store';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  // ... (other icons)
};

const NavigationConfig = () => {
  const { currentUser } = useSelector((state) => {
    return {
      currentUser: state && state.profile && state.profile.events && state.profile.events.response,
    };
  });

  console.log(currentUser && currentUser.menu && currentUser.menu[7] && currentUser.menu[7].sub_menu_name, 'd504f5gfg464', currentUser && currentUser.menu, currentUser && currentUser.menu && currentUser.menu[7]);
  // console.log(currentUser && currentUser.menu && currentUser.menu[7], 'd504f5gfg464');
  // const [networkMenu, setNetworkMenu] = useState('')

  const userMenus = localStorage.getItem('agentMenu');

  let networkmenu = Number(userMenus)

  useEffect(() => {
    if (currentUser && currentUser !== undefined) {
      // setNetworkMenu(currentUser && currentUser.is_agent)
      networkmenu = currentUser && currentUser.is_agent
    }
  }, [currentUser && currentUser.is_agent])



  // useEffect(() => {
  //   if (userMenus && userMenus !== undefined) {
  //     setNetworkMenu(Number(userMenus))
  //     console.log('menuing 2');
  //   }
  // }, [userMenus])


  console.log(networkmenu, 'menuing', Number(userMenus));






  const navConfig = [
    {
      // subheader: 'management',
      items: [
        // MANAGEMENT : USER
        // { title: 'Dashboard', path: '#'},
        { title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard },
        { title: 'Profile', path: PATH_DASHBOARD.general.profile },
        { title: 'Search', path: PATH_DASHBOARD.talent.search },
        // { title: 'Search', path: PATH_DASHBOARD.search.talentSearch },
        {
          title: 'Jobs',
          path: PATH_DASHBOARD.jobs.root,
          // path: PATH_DASHBOARD.jobs.postings,
        },
        { title: 'Projects', path: PATH_DASHBOARD.project.projects },
        { title: 'My Contacts', path: PATH_DASHBOARD.myContact },
        // { title: 'MyContacts', path: PATH_DASHBOARD.project.projects },
        { title: 'Calendar', path: PATH_DASHBOARD.calendar },
        { title: 'Inbox', path: PATH_DASHBOARD.inBox.talent },
        {
          title: 'Network',
          path: PATH_DASHBOARD.connectnetwork.root,
          icon: ICONS.user,
          children: networkmenu && networkmenu === 1
            ? [
              { title: 'Followers', path: PATH_DASHBOARD.connectnetwork.followers.root },
              { title: 'Following', path: PATH_DASHBOARD.connectnetwork.following.root },
              { title: 'My Network', path: PATH_DASHBOARD.connectnetwork.mynetwork },
              { title: 'Invitations', path: PATH_DASHBOARD.connectnetwork.myinvitation.root },
            ]
            : [
              { title: 'Followers', path: PATH_DASHBOARD.connectnetwork.followers.root },
              { title: 'Following', path: PATH_DASHBOARD.connectnetwork.following.root },
            ],
        },
        { title: 'Website', path: PATH_DASHBOARD.website.root },
        { title: 'Staff', path: PATH_DASHBOARD.staff.teams },
        // { title: 'Reports', path: PATH_DASHBOARD.networking },
        {
          title: 'Reports',
          path: PATH_DASHBOARD.reports.root,
          icon: ICONS.user,
          children: [
            { title: 'Activity', path: PATH_DASHBOARD.reports.activity },
          ],
        },

        // { title: 'Settings', path: PATH_DASHBOARD.setting },
        { title: 'Settings', path: PATH_DASHBOARD.setting.root },
        { title: 'Subscription', path: PATH_DASHBOARD.subscription },

        // { title: 'View Job Dashboard', path: PATH_PROJECTS.jobs.viewjobs },
        { title: 'Activity', path: PATH_DASHBOARD.activityLog, }
      ],
    }
  ];

  return navConfig; // Return the navConfig array
};

export default NavigationConfig;
