

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

UserMoreMenuProj.propTypes = {
  onPause: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserMoreMenuProj(props) {

  const { onPreviewProj, viewJobPost, EditJobPost, onPause, EnableJobSeek, unPublishJobPost, onCloseFunction, jobType, jobStatus, jobPublish, onEdit, onPhpto, onVideos, onDelete, PublishProject, CheckPublish, IsDelete } = props;

  const { updateJobPostPausedata, updateUnPublishSuccData, deleteProjectSucc } = useSelector((state) => {
    return {
      updateJobPostPausedata: state && state.myjob && state.myjob.updateJobPostingPaused && state.myjob.updateJobPostingPaused.status,
      updateUnPublishSuccData: state && state.myjob && state.myjob.updateUnPublishSucc && state.myjob.updateUnPublishSucc.status,
      deleteProjectSucc: state && state.projServices && state.projServices.deleteProjectSucc,

    };
  });


  const [open, setOpen] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (IsDelete && IsDelete === 'Yes') {
      setOpen(null);
    }
  }, [IsDelete]);

  useEffect(() => {
    if (updateJobPostPausedata === 200) {
      setOpen(null);
    }
  }, [updateJobPostPausedata]);

  useEffect(() => {
    if (updateUnPublishSuccData === 200) {
      setOpen(null);
    }
  }, [updateUnPublishSuccData]);
  useEffect(() => {
    if (deleteProjectSucc && deleteProjectSucc.status === 200) {
      setOpen(null);
    }
  }, [deleteProjectSucc]);


  const HandleViewJobPost = () => {
    viewJobPost()
    handleClose()
  }

  const onPreview = () => {
    onPreviewProj()
    handleClose()
  }
  const onPublish = () => {
    PublishProject()
    handleClose()
  }
  const HandleEditJobPost = () => {
    EditJobPost()
    handleClose()
  }
  const HandleSeek = () => {
    EnableJobSeek()
    handleClose()
  }
  const HandleOnPause = () => {
    onPause()
    handleClose()
  }
  const HandleUnPublishJobPost = () => {
    unPublishJobPost()
    handleClose()
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} sx={{ color: "#000000" }} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem value="" onClick={onEdit} >
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:edit-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Edit Info" />
        </MenuItem>
        <MenuItem value="" onClick={onPhpto} >
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:image-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Add Photos" />
        </MenuItem>
        <MenuItem value="" onClick={onVideos}>
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:video-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Add Videos" />
        </MenuItem>
        {/* <MenuItem value="" onClick={onPreview}> */}
        <MenuItem value="" onClick={onEdit}>
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:eye-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Preview" />
        </MenuItem>
        <MenuItem value="" onClick={onPublish}>
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:paper-plane-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary={CheckPublish && CheckPublish === "0" ? "Publish" : "UnPublish"} />
        </MenuItem>
        <MenuItem value="" onClick={onDelete}>
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:trash-2-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </MenuPopover>
    </>
  );
}
