
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function projectvideoblockcontent() {
    return (
        <Stack
            spacing={2}
            sx={{ width: 1, textAlign: 'left' }}
        >
            <Box p={1.5}>
                <Typography fontSize={16}>
                    Choose videos to upload
                </Typography>
            </Box>
        </Stack>
    );
}