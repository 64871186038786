import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  saveCastJobBeforeResp: [],
  saveCastJobPostResponse: [],
  saveCrewJobPostResponse: [],
  saveCrewJobBeforeResponse: [],
  getCastJoResponse: [],
  getCerwJobResponse: [],
  castJobPostSuccResponse: [],
  crewJobPostSuccResponse: [],
  saveOfficeJobResponse: [],
  saveOfficeJobBeforePreviewRes: [],
  getOfficeJobResponse: [],
  officeJobPostSuccResponse: [],
  settingJobPostSuccResponse: [],
  addRecruiterManagerResponse: [],
  getOfficeRollResponse: [],
  getAllProfessionsResponse: [],
  ProfDropDownResponse: [],
  jobIDResponse: [],
  getJobsSuccResponse: [],
  updateCastResponse: [],
  updateCastBeforeResponse: [],
  updateOfficeJobResponsenew: [],
  updateOfficeJobBeforeResponse: [],
  updateCrewJobResponse: [],
  updateCrewJobBeforeResponse: [],
  getIndustryTypeResponse: [],
  getDropDownsResponse: [],
  deleteDraftJobResponse: [],
  OfficeJobSuccResponse: [],
  CastJobSuccResponse: [],
  CrewJobSuccResponse: [],
  CitiesDataResponse: [],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    getgetIndustryTypeSucc(state, action) {
      state.isLoading = false;
      state.getIndustryTypeResponse = action.payload;
    },
    castJobPostSucc(state, action) {
      state.isLoading = false;
      state.castJobPostSuccResponse = action.payload;
    },

    crewJobPostSucc(state, action) {
      state.isLoading = false;
      state.crewJobPostSuccResponse = action.payload;
    },

    saveCastJobBeforeSucc(state, action) {
      state.isLoading = false;
      state.saveCastJobBeforeResp = action.payload;
    },
    saveCastJobPostSucc(state, action) {
      state.isLoading = false;
      state.saveCastJobPostResponse = action.payload;
    },
    getCastJobSucc(state, action) {
      state.isLoading = false;
      state.getCastJoResponse = action.payload;
    },

    getCrewJobSucc(state, action) {
      state.isLoading = false;
      state.getCerwJobResponse = action.payload;
    },

    saveCrewJobPostSucc(state, action) {
      state.isLoading = false;
      state.saveCrewJobPostResponse = action.payload;
    },
    saveCrewJobBeforeSucc(state, action) {
      state.isLoading = false;
      state.saveCrewJobBeforeResponse = action.payload;
    },

    saveOfficeJobSucc(state, action) {
      state.isLoading = false;
      state.saveOfficeJobResponse = action.payload;
    },
    saveOfficeJobBeforePreviewSucc(state, action) {
      state.isLoading = false;
      state.saveOfficeJobBeforePreviewRes = action.payload;
    },
    getOfficeJobSucc(state, action) {
      state.isLoading = false;
      state.getOfficeJobResponse = action.payload;
    },
    officeJobPostSucc(state, action) {
      state.isLoading = false;
      state.officeJobPostSuccResponse = action.payload;
    },
    settingJobPostSucc(state, action) {
      state.isLoading = false;
      state.settingJobPostSuccResponse = action.payload;
    },
    addRecruiterManagerJobPostSucc(state, action) {
      state.isLoading = false;
      state.addRecruiterManagerResponse = action.payload;
    },
    getOfficeRollSucc(state, action) {
      state.isLoading = false;
      state.getOfficeRollResponse = action.payload;
    },
    getAllProfessionsSucc(state, action) {
      state.isLoading = false;
      state.getAllProfessionsResponse = action.payload;
    },
    getProfessionDropSucc(state, action) {
      state.isLoading = false;
      state.ProfDropDownResponse = action.payload;
    },
    JobIdParamsSucc(state, action) {
      state.isLoading = false;
      state.jobIDResponse = action.payload;
    },
    getJobsSucc(state, action) {
      state.isLoading = false;
      state.getJobsSuccResponse = action.payload;
    },
    updateCastJobPostSucc(state, action) {
      state.isLoading = false;
      state.updateCastResponse = action.payload;
    },
    updateCastJobBeforeSucc(state, action) {
      state.isLoading = false;
      state.updateCastBeforeResponse = action.payload;
    },

    updateOfficeJobSucc(state, action) {
      const event = action.payload;
      state.isLoading = false;
      state.updateOfficeJobResponsenew = action.payload;
    },
    updateOfficeJobBeforeSucc(state, action) {
      state.isLoading = false;
      state.updateOfficeJobBeforeResponse = action.payload;
    },
    updateCrewJobSucc(state, action) {
      state.isLoading = false;
      state.updateCrewJobResponse = action.payload;
    },
    updateCrewJobBeforeSucc(state, action) {
      state.isLoading = false;
      state.updateCrewJobBeforeResponse = action.payload;
    },
    getDropDownsSucc(state, action) {
      state.isLoading = false;
      state.getDropDownsResponse = action.payload;
    },
    deleteDraftJobSucc(state, action) {
      state.isLoading = false;
      state.deleteDraftJobResponse = action.payload;
    },
    OfficeJobSucc(state, action) {
      state.isLoading = false;
      state.OfficeJobSuccResponse = action.payload;
    },
    CastJobSucc(state, action) {
      state.isLoading = false;
      state.CastJobSuccResponse = action.payload;
    },
    CrewJobSucc(state, action) {
      state.isLoading = false;
      state.CrewJobSuccResponse = action.payload;
    },
    BudgetRoleDataSucc(state, action) {
      state.isLoading = false;
      state.BudgetRoleDataResponse = action.payload;
    },
    CitiesDataSucc(state, action) {
      state.isLoading = false;
      state.CitiesDataResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function saveCastJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveCastJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function saveCastJobBeforePost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveCastJobBeforeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function postCastJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.castJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCastJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCastJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveCrewJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());

    // const validFromMillis = parseInt(Date.parse(data.duration_from), 10);
    // const validfrom = new Date(validFromMillis);
    // const dateStart = validfrom.toISOString().substring(0, 10);

    // const validFromMill = parseInt(Date.parse(data.duration_to), 10);
    // const validfrom1 = new Date(validFromMill);
    // const dateEnd = validfrom1.toISOString().substring(0, 10);

    // const validFromMillsss = parseInt(Date.parse(data.apply_by_date), 10);
    // const validfrom11 = new Date(validFromMillsss);
    // const applybydate = validfrom11.toISOString().substring(0, 10); 
    // const newEvent = {
    //   job_id : data.job_id,
    //   job_type: 'crew_job',
    //   profession_role_type:"crew",
    //   project_enc_id : data.project_enc_id,
    //   profession : data.profession, 
    //   gender:  data.gender,
    //   // profession_type : data.profession_type, 
    //   no_of_position: data.no_of_position,
    //   duration_from: dateStart,
    //   duration_to: dateEnd, 
    //   shooting_days: data.shooting_days,  
    //   pay_range:  data.pay_range,
    //   // experience:  data.experience,
    //   pay_range_value: data.pay_range_value,
    //   // experience_slug:  data.experience_slug,
    //   location: data.location,
    //   apply_by_date:applybydate,
    //   role_description : data.role_description,
    //   experience_years_from: data.experience_years_from,
    //   experience_years_to: data. experience_years_to,
    //   language_skill: data.language_skill,
    //   employment_type: data.employment_type,
    //   position_type: data.position_type,
    //   skillset: data.skillset,
    // }; 

    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveCrewJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveCrewJobBeforePost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());

    // const validFromMillis = parseInt(Date.parse(data.duration_from), 10);
    // const validfrom = new Date(validFromMillis);
    // const dateStart = validfrom.toISOString().substring(0, 10);

    // const validFromMill = parseInt(Date.parse(data.duration_to), 10);
    // const validfrom1 = new Date(validFromMill);
    // const dateEnd = validfrom1.toISOString().substring(0, 10);

    // const validFromMillsss = parseInt(Date.parse(data.apply_by_date), 10);
    // const validfrom11 = new Date(validFromMillsss);
    // const applybydate = validfrom11.toISOString().substring(0, 10);

    // console.log('ou(*&^*)',data)
    // const newEvent = {
    //   job_id : data.job_id,
    //   job_type: 'crew_job',
    //   profession_role_type:"crew",
    //   project_enc_id : data.project_enc_id,
    //   profession : data.profession, 
    //   gender:  data.gender,
    //   // profession_type : data.profession_type, 
    //   no_of_position: data.no_of_position,
    //   duration_from: dateStart,
    //   duration_to: dateEnd, 
    //   shooting_days: data.shooting_days,  
    //   pay_range:  data.pay_range,
    //   // experience:  data.experience,
    //   pay_range_value: data.pay_range_value,
    //   // experience_slug:  data.experience_slug,
    //   location: data.location,
    //   apply_by_date:applybydate,
    //   role_description : data.role_description,
    //   experience_years_from: data.experience_years_from,
    //   experience_years_to: data. experience_years_to,
    //   language_skill: data.language_skill,
    //   employment_type: data.employment_type,
    //   position_type: data.position_type,
    //   skillset: data.skillset,
    // }; 

    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveCrewJobBeforeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getCrewtJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCrewJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function postCrewJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.crewJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCrewJobBeforeResponse([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCrewJobPostSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.crewJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function saveOfficeJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveOfficeJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function saveOfficeJobBeforePreviewPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveOfficeJobBeforePreviewSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getOfficeJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getOfficeJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function postOfficeJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.officeJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function postSettingJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.settingJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function addRecruiterManagerSettingJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/add_recruiting_manager/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.addRecruiterManagerJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getOfficeJobRoles(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_get_professions/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getOfficeRollSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAllProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_professions/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAllProfessionsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProfessionDrop(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_onscreen_professions/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProfessionDropSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function sendJobId(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.JobIdParamsSucc(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getJobs(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateCastJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateCastJobPostSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateCastJobBeforePost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateCastJobBeforeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateOfficeJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateOfficeJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateOfficeJobBeforePost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateOfficeJobBeforeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateCrewJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateCrewJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateCrewJobBeforePost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_job_posting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateCrewJobBeforeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DeleteDraftJobPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_draft_job/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteDraftJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getBudgetRoleData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_role_data/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.BudgetRoleDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getCitiesData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_city/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.CitiesDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearCitiesData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CitiesDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearBudgetRoleData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.BudgetRoleDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearPostSettingJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.settingJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearJobPosts(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateCrewJobSucc([]));
      dispatch(slice.actions.updateOfficeJobSucc([]));
      dispatch(slice.actions.updateCastJobPostSucc([]));
      dispatch(slice.actions.deleteDraftJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearOfficeJobPosts(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveOfficeJobBeforePreviewSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearOofficeJobPostSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.officeJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearAllProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAllProfessionsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCastJobPosts(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCastJobBeforeSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCcastJobPostSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.castJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearOfficeJobSave(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveOfficeJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCastJobSave(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCastJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearupdateOfficeJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateOfficeJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCrewJobSave() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCrewJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIndustryType() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_industry_type/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getgetIndustryTypeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function geDropDowns(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_dropdowns/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getDropDownsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearsaveCrewJobBeforeSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCrewJobBeforeSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetJobsResp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJobsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function OfficeJobUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_no_of_position/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.OfficeJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearOfficeJobSuccResp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.OfficeJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CastJobUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_no_of_position/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.CastJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearCastJobSuccResp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CastJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CrewJobUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_no_of_position/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.CrewJobSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearCrewJobSuccResp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CrewJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleargetCastJobSuccResp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCastJobSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearaddRecruiterManagerSettingJob(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.addRecruiterManagerJobPostSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}