import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  departmentsSuccResp: [],
  templateSuccResp: [],
  addTempSuccResp: [],
  updateRoleSuccResp: [],
  deleteRoleSuccResp: [],
  publishTeamSuccResp: [],
  printableDataSuccResp: [],
  BudgetSuccResp: [],
  TeamSuccResp: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE EVENT
    departmentsSuccess(state, action) {
        state.isLoading = false;
        state.departmentsSuccResp = action.payload;
    },
    // CREATE EVENT
    templateSuccess(state, action) {
        state.isLoading = false;
        state.templateSuccResp = action.payload;
    },
    // CREATE EVENT
    addTemplateSuccess(state, action) {
        state.isLoading = false;
        state.addTempSuccResp = action.payload;
    },
    // CREATE EVENT
    updateRoleSuccess(state, action) {
        state.isLoading = false;
        state.updateRoleSuccResp = action.payload;
    },
    // CREATE EVENT
    deleteRoleSuccess(state, action) {
        state.isLoading = false;
        state.deleteRoleSuccResp = action.payload;
    },
    // CREATE EVENT
    publishTeamSuccess(state, action) {
        state.isLoading = false;
        state.publishTeamSuccResp = action.payload;
    },
    // CREATE EVENT
    BudgetSuccess(state, action) {
        state.isLoading = false;
        state.BudgetSuccResp = action.payload;
    },
    // CREATE EVENT
    TeamsSuccess(state, action) {
        state.isLoading = false;
        state.TeamSuccResp = action.payload;
    },
    // CREATE EVENT
    printDataSuccess(state, action) {
        state.isLoading = false;
        state.printableDataSuccResp = action.payload;
    },
     
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    
  },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------
 
export function getSelectedTemplate(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/select_template/',data)
        dispatch(slice.actions.templateSuccess(response.data));
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
            dispatch(slice.actions.hasError(error));
          } else{
            dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
          }
      }
    };
  }

  export function getBudget(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_budget/',data)
        dispatch(slice.actions.BudgetSuccess(response.data));
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
            dispatch(slice.actions.hasError(error));
          } else{
            dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
          }
      }
    };
  }

  export function getTeams(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_budget/',data)
        dispatch(slice.actions.TeamsSuccess(response.data));
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
            dispatch(slice.actions.hasError(error));
          } else{
            dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
          }
      }
    };
  }

  export function PublishTeam(data) {
      return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.post('/publish_team/',data)
          dispatch(slice.actions.publishTeamSuccess(response.data));
        } catch (error) {
          if((error && error.status !== "") && (error.status !== undefined) ){
              dispatch(slice.actions.hasError(error));
            } else{
              dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
            }
        }
      };
    }

  export function getDepartments(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.get('/get_depts/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.departmentsSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function AddTemplates(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/add_template_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addTemplateSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }

      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function UpdateTemplates(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/add_template_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updateRoleSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function DeleteRole(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/delete_role_data/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.deleteRoleSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function getPrintTableData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      const tokenVal = window.localStorage.getItem("accessToken")
      try {
        const response = await axios.post('/get_publish_records/',data)
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.printDataSuccess(response.data));
        }else{
          dispatch(slice.actions.hasError(response.data))
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }


  export function clearPrintableDataError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.printDataSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }


  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearTemplateSuccData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.templateSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearUpdateRoleSuccData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.updateRoleSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearAddRoleSuccData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addTemplateSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearPublishTeam(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.publishTeamSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearBudget(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.BudgetSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function clearTeams(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.TeamsSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  } 


  export function clearDeleteTeams(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.deleteRoleSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }


