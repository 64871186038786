import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getMyStaffList: [],
  getMyStaffSingleList: [],
  getMyRoleList: [],
  getMyStaffDelete: [],
  getMyStaffCreate:[],
  isOpenModal: false, 
};

const slice = createSlice({
  name: 'Staff List',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
 
   
    getStaffListSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffList = action.payload;
    },

    getStaffSingleListSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffSingleList = action.payload;
    },

    clearGetStaffSingleListSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffSingleList = [];
    },

    getRoleListSucc(state, action) {
      state.isLoading = false;
      state.getMyRoleList = action.payload;
    },

    getStaffDeleteSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffDelete = action.payload;
    },

    getStaffCreateSucc(state, action) {
      state.isLoading = false;
      state.getMyStaffCreate = action.payload;
    },
    
   
    

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getStaffList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_members/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStaffListSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function getStaffSingleList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_members/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStaffSingleListSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}



export function getRoleList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_roles/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getRoleListSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}


export function getDeleteStaff(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_team_member/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStaffDeleteSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getCreateStaff(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_team_member/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getStaffCreateSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}


export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCreateTeam(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getStaffCreateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearCreate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearGetStaffSingleListSucc());
      // dispatch(slice.actions.getStaffListSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearDelete(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getStaffDeleteSucc());
      // dispatch(slice.actions.getStaffListSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


