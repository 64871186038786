import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  followResponse: [], 
  followLoginRes: [],
  followingEmployersResponse:[],
  networkResponse:[],
  networkDeleteResponse:[],
  inviteStatusResponse:[],
  inviteResendStatusResponse:[],
  inviteEmailResponse:[],
  inviteSocialResponse:[],
  inviteSocialUpdateResponse:[],
  inviteBulkResponse:[],
  inviteBulkFailureResp:[],
  totalBulkFailureResp:[],
  followEmployersResponse:[],
  UnfollowResponse:[],
  followTalentResponse:[],
  followBackRes:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  bookmarSavedResponse:[],
  BookmarksSavedResponse:[]
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
   
    MyNetworkRes(state, action) {
      state.isLoading = false;
      state.networkResponse = action.payload;
    }, 
   
    BookmarksSavedRes(state, action) {
      state.isLoading = false;
      state.BookmarksSavedResponse = action.payload;
    }, 
    MyNetworkDeleteRes(state, action) {
      state.isLoading = false;
      state.networkDeleteResponse = action.payload;
    }, 
    SavedBookMark(state, action){
        state.isLoading = false;
      state.bookmarSavedResponse = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getBookmarks(data) { 
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_bookmarkedtalent/', data);
      // const response = await axios.post('/get_employer_bookmarked_talent/', data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.MyNetworkRes(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data))
      }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function getSavedBookmarks() { 
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_bookmarked_id/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.BookmarksSavedRes(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data))
      }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function getMyBookmarkDelete(data) { 
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_bookmarked_talent/', data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.MyNetworkDeleteRes(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data))
      }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
    }
  };
}

export function savebookMarked(data) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/save_bookmarked_talent/', data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.SavedBookMark(response.data));
          }else{
          dispatch(slice.actions.hasError(response.data))
        }
        } catch (error) {
          if((error && error.status !== "") && (error.status !== undefined) ){
            dispatch(slice.actions.hasError(error));
          } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
          }
      }
    };
  }

export function clearBookmarksSaved(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.BookmarksSavedRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearBookmarkDelete(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.MyNetworkDeleteRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSavedBookmark(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SavedBookMark([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
