import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from '../../../../redux/store';

// components
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

UserMoreMenuPhoto.propTypes = {
  onPause: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserMoreMenuPhoto(props) {

  const { viewJobPost, EditJobPost, onPause, EnableJobSeek, unPublishJobPost, onCloseFunction, jobType, jobStatus, jobPublish, onEdit, onPreview, onDelete } = props;
  const { updateJobPostPausedata, updateUnPublishSuccData, deleteProPhotosSucc, UpdateServiceData } = useSelector((state) => {
    return {
      updateJobPostPausedata: state && state.myjob && state.myjob.updateJobPostingPaused && state.myjob.updateJobPostingPaused.status,
      updateUnPublishSuccData: state && state.myjob && state.myjob.updateUnPublishSucc && state.myjob.updateUnPublishSucc.status,
      deleteProPhotosSucc: state && state.projServices && state.projServices.deleteProPhotosSucc,
      UpdateServiceData: state && state.projServices && state.projServices.profileServiceUpdate && state.projServices.profileServiceUpdate,



    };
  });


  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (updateJobPostPausedata === 200) {
      setOpen(null);
    }
  }, [updateJobPostPausedata]);

  useEffect(() => {
    if (updateUnPublishSuccData === 200) {
      setOpen(null);
    }
  }, [updateUnPublishSuccData]);

  useEffect(() => {
    if (deleteProPhotosSucc && deleteProPhotosSucc.status === 200) {
      setOpen(null);
    }
  }, [deleteProPhotosSucc]);

  useEffect(() => {
    if (UpdateServiceData && UpdateServiceData.status === 200) {
      setOpen(null);
    }
  }, [UpdateServiceData]);

  const HandleViewJobPost = () => {
    viewJobPost()
    handleClose()
  }
  const HandleEditJobPost = () => {
    EditJobPost()
    handleClose()
  }
  const HandleSeek = () => {
    EnableJobSeek()
    handleClose()
  }
  const HandleOnPause = () => {
    onPause()
    handleClose()
  }
  const HandleUnPublishJobPost = () => {
    unPublishJobPost()
    handleClose()
  }
  return (
    <>
     <Tooltip title="Menu" >
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={25} height={25} sx={{ color: "#fff" }} />
      </IconButton>
      </Tooltip>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem value="" onClick={() => { onEdit(); handleClose(); }}  >
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:edit-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Edit " />
        </MenuItem>
        <MenuItem value="" onClick={() => { onPreview(); handleClose(); }}>
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:eye-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Preview" />
        </MenuItem>
        <MenuItem value="" onClick={() => { onDelete(); handleClose(); }} >
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:trash-2-outline'} width={20} height={20} sx={{ color: "#000000" }} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>

      </MenuPopover>
    </>
  );
}
