import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import createProfile from './slices/createProfile'
import talentIndustries from './slices/talentIndustries';
import skillset from './slices/skillset';
import jobPost from './slices/jobPost';
import settingsCredit from './slices/settings';
import myjobposting from './slices/myjob';
import JobSeek from './slices/JobSeek';
import jobCandidateList from './slices/jobCandidateList';
import TalentSearch from './slices/talentSearch';
import companyProfile from './slices/companyProfile';
import ProjectService from './slices/ProjectService';
import Followers from './slices/Followers';
import inbox from './slices/inbox';
import ForgotPass from './slices/ForgotPass';
import ViewCandidate from './slices/ViewCandidate';
import profileIntro from './slices/profileIntro';
import jobInterview from './slices/jobInterviews';
import Staff from './slices/staff';
import Projects from './slices/Projects';
import projectInfo from './slices/projectInfo';
import BudgetAndTeam from './slices/BudgetAndTeam';
import projectTimeline from './slices/projectTimeline';
import Subcriptions from './slices/subscription';
import MyContactReducer from './slices/MyContact';
import TeamPayments from './slices/teamsPayments';
import BookmarkedTalentReducer from './slices/BookmarkedTalent';
import TeamAccessSettingReducer from './slices/TeamAccessSetting';
import DashBoardNotificationReducer from './slices/DashboardNotification';
import Activitylog from './slices/activitylog';
import Wallet from './slices/Wallet';
import OpenDialogue from './slices/OpenDialogue';
import WebsiteGallery from './slices/WebsiteGallery';
import LocationDetail from './slices/locationDetails';



// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  skillsetInd: skillset,
  profile: createProfile,
  industries: talentIndustries,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  settings: settingsCredit,
  postJobs: jobPost,
  intro: profileIntro,
  myjob: myjobposting,
  jobSeeker: JobSeek,
  jobCandidateLists: jobCandidateList,
  jobInterviews: jobInterview,
  talentSearch: TalentSearch,
  companyprofile: companyProfile,
  projServices: ProjectService,
  // projServices:ProjectService,
  Follow: Followers,
  inboxed: inbox,
  forgotPas: ForgotPass,
  viewCandidateList: ViewCandidate,
  staff: Staff,
  project: projectInfo,
  projectDetail: Projects,
  timelineProject: projectTimeline,
  teamPayments: TeamPayments,
  budgetANDteam: BudgetAndTeam,
  product: persistReducer(productPersistConfig, productReducer),
  subscription: Subcriptions,
  mycontacts: MyContactReducer,
  BookmarkedTalent: BookmarkedTalentReducer,
  TeamAccessSetting: TeamAccessSettingReducer,
  DashBoardNotification: DashBoardNotificationReducer,
  activitylog: Activitylog,
  wallet: Wallet,
  otpModal: OpenDialogue,
  websiteGallery: WebsiteGallery,
  LocationDetails: LocationDetail,

});

export { rootPersistConfig, rootReducer };
