import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  gettimelineDurationProResp:[],
  gettimelineShootScheProResp:[],
  gettimelineEventProResp:[],
  timelineDurationProjectResp:[],
  timelineShootSchedProjectResp:[],
  timelineEventProjectResp:[],
  edittimelineDurationProjectResp:[],
  edittimelineShootSchedProjectResp:[],
  edittimelineEventProjectResp:[],
  deleteProjectShootSuccResp:[],
  deleteProjectDurationSuccResp:[],
  deleteProjectEventSuccResp:[],
  isOpenModal: false, 
};

const slice = createSlice({
  name: 'Project Timeline',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get
    getTimelineDurationProSucc(state, action) {
      state.isLoading = false;
      state.gettimelineDurationProResp = action.payload;
    },
    getTimelineShootScheProSucc(state, action) {
      state.isLoading = false;
      state.gettimelineShootScheProResp = action.payload;
    },
    getTimelineEventProSucc(state, action) {
      state.isLoading = false;
      state.gettimelineEventProResp = action.payload;
    },

    // Save
    TimelineDurationProjectSucc(state, action) {
      state.isLoading = false;
      state.timelineDurationProjectResp = action.payload;
    },
    TimelineShootSchedProjectSucc(state, action) {
      state.isLoading = false;
      state.timelineShootSchedProjectResp = action.payload;
    },
    TimelineEventProjectSucc(state, action) {
      state.isLoading = false;
      state.timelineEventProjectResp = action.payload;
    },
    // Edit
    EditTimelineDurationProjectSucc(state, action) {
      state.isLoading = false;
      state.edittimelineDurationProjectResp = action.payload;
    },
    EditTimelineShootSchedProjectSucc(state, action) {
      state.isLoading = false;
      state.edittimelineShootSchedProjectResp = action.payload;
    },
    EditTimelineEventProjectSucc(state, action) {
      state.isLoading = false;
      state.edittimelineEventProjectResp = action.payload;
    },
    // Delete
    DeleteProjectEventSucc(state, action) {
      state.isLoading = false;
      state.deleteProjectEventSuccResp = action.payload;
    },
    DeleteProjectDurationSucc(state, action) {
      state.isLoading = false;
      state.deleteProjectDurationSuccResp = action.payload;
    },
    DeleteProjectShootSucc(state, action) {
      state.isLoading = false;
      state.deleteProjectShootSuccResp = action.payload;
    },
   

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------


// export function getRoleList(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/get_roles/');
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getRoleListSucc(response.data));
//         }else{
//         dispatch(slice.actions.hasError(response.data)) 
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//       } else{
//         dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//       }
//     }
//   };
// }


export function getTimelineDurationPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getTimelineDurationProSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getTimelineShootSchePro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getTimelineShootScheProSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getTimelineEventPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getTimelineEventProSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SaveTimelineDurationPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.TimelineDurationProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SaveTimelineShootSchedPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.TimelineShootSchedProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SaveTimelineEventPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.TimelineEventProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function EditTimelineDurationPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.EditTimelineDurationProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function EditTimelineShootSchedPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.EditTimelineShootSchedProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function EditTimelineEventPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_projects_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.EditTimelineEventProjectSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function DeleteTimelineDurationProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_project_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.DeleteProjectDurationSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function DeleteTimelineShootProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_project_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.DeleteProjectShootSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function DeleteTimelineEventProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_project_timeline/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.DeleteProjectEventSucc(response.data));
        }else{
        dispatch(slice.actions.hasError(response.data)) 
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function clearTimelineDurationPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.TimelineDurationProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearTimelineShootSchedPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.TimelineShootSchedProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearTimelineEventPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.TimelineEventProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearEditTimelineDurationPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EditTimelineDurationProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearEditTimelineShootSchedPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EditTimelineShootSchedProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearEditTimelineEventPro(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EditTimelineEventProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearDeleteProjectShoot(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteProjectShootSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearDeleteProjectDurationSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteProjectDurationSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearDeleteProjectEventSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteProjectEventSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



