import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';

// import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_PROFILE } from '../../../routes/paths';
import { useDispatch, useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { cleardata, clearDashboarddata, employerLogout } from '../../../redux/slices/createProfile';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser, employerData, profileData } = useSelector((state) => {
    return {
      currentUser: state && state.profile && state.profile.profileImage,
      employerData: state && state.profile && state.profile.dashboardDataSucc && state.profile.dashboardDataSucc.response && state.profile.dashboardDataSucc.response.profile,
      profileData: state && state.profile && state.profile.events && state.profile.events.response && state.profile.events.response.get_employer_data,
    };
  });

  const { user, logout } = useAuth();
  const [updateProfile, setUpdateProfile] = useState([])
  const isMountedRef = useIsMountedRef();
  const [disName, setDisName] = useState("");
  const [disProfile, setDisProfile] = useState("");
  const [companyType, setCompanyType] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const employerFilledProfile = localStorage.getItem('companyProfileFilled');

  useEffect(() => {
    if (employerFilledProfile && employerFilledProfile !== undefined && employerFilledProfile !== null) {
      if (employerFilledProfile && employerFilledProfile === 'Yes') {
        setDisProfile('Filled');
      }
    }
  }, [employerFilledProfile])


  const handleLogout = async () => {
    try {
      const employerID = localStorage.getItem('userEmployerEncId');
      const payload = {
        "enc_id": employerID,
        "device_type": "web"
      };
      dispatch(employerLogout(payload));
      await logout();
      navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourlEmp');
        setUpdateProfile(userPhotourl)
      }, 1000);
    }
  }, [currentUser])


  const employerSlug = localStorage.getItem('employerSlug');

  const companysType = localStorage.getItem("companyType")
  const employerIcon = localStorage.getItem('employerIcon');
  const employerIconNew = localStorage.getItem('NewemployerIcon');

  useEffect(() => {
    // if (employerIcon !== "") {
    //   setTimeout(() => {
    //     setUpdateProfile(employerIcon)
    //     console.log('peppdsfdsfsdf 1')
    //   }, 1000);
    // }
    if (employerIconNew !== "") {
      setTimeout(() => {
        setUpdateProfile(employerIconNew)
        console.log('peppdsfdsfsdf 1')
      }, 1000);
    }
    if (employerSlug !== "") {
      setTimeout(() => {
        setDisName(employerSlug)
        console.log('peppdsfdsfsdf 1')
      }, 1000);
    }
    if (companysType !== "") {
      setTimeout(() => {
        setCompanyType(companysType)
        console.log('peppdsfdsfsdf 1')
      }, 1000);
    }
  }, [employerSlug, employerIcon, employerIconNew, companysType]);

  console.log(companysType, 'dfgkdfg4r', companyType)

  useEffect(() => {
    if (employerData && employerData !== "" || employerData !== undefined) {
      setDisName(employerData && employerData.slug)
      setCompanyType(employerData && employerData.company_type)
      if (employerData.icon_image && employerData.icon_image !== '' && employerData.icon_image !== null) {
        const datetimeValue = new Date().toISOString();
        const imageUrlF = employerData.icon_image;
        const avtarUrlWithDatetimeicon = `${imageUrlF}?datetime=${encodeURIComponent(datetimeValue)}`;
        localStorage.setItem('userPhotourlEmp', avtarUrlWithDatetimeicon);
        setUpdateProfile(avtarUrlWithDatetimeicon);
      }
    }
  }, [employerData])

  useEffect(() => {
    if (profileData && profileData !== "" || profileData !== undefined) {
      setDisName(profileData && profileData.slug)
      setCompanyType(profileData && profileData.company_type)
      if (profileData.icon_image && profileData.icon_image !== '' && profileData.icon_image !== null) {
        const datetimeValue = new Date().toISOString();
        const imageUrlF = profileData.icon_image;
        const avtarUrlWithDatetimeicon = `${imageUrlF}?datetime=${encodeURIComponent(datetimeValue)}`;
        localStorage.setItem('userPhotourlEmp', avtarUrlWithDatetimeicon);
        setUpdateProfile(avtarUrlWithDatetimeicon);
      }
    }
  }, [profileData])




  const [toPhoto, setToPhoto] = useState(PATH_PROFILE.locationsDetails);
  const [invitation, setInvitation] = useState(PATH_DASHBOARD.connectnetwork.myinvitation.root);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar updateProfile={updateProfile} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        {/* {disName && disName !== '' && disProfile && disProfile !== '' ?
          <>
            {disProfile === 'Filled' ?
        <MenuItem >
          <Link component={RouterLink} to={`${toPhoto}`} target='_blank'>
            Profile
          </Link>
        </MenuItem>
         : null}
         </>
         : ""} */}


        {companyType && companyType !== 'Company' ?
          <MenuItem >
            <Link component={RouterLink} to={`${invitation}`} target='_blank'>
              Send Invitation
            </Link>
          </MenuItem>
          : ""}


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
