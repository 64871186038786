import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, AppBar, Toolbar, Badge, IconButton } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDispatch, useSelector } from "react-redux";

import { PATH_DASHBOARD } from '../../../routes/paths';
import { CleargetPayoutData } from '../../../redux/slices/Wallet';



const Wallet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleWalletClick = () => {
        navigate(PATH_DASHBOARD.wallet.payouts);
    }

    useEffect(() => {
        dispatch(CleargetPayoutData());
    }, []);



    return (
        <div> <IconButton onClick={handleWalletClick}>

            <AccountBalanceWalletIcon color="action" sx={{ fontSize: '1.8rem' }} />

        </IconButton></div>
    )
}

export default Wallet