
import PropTypes from 'prop-types';
import { useState ,useEffect} from 'react';
// @mui
import { MenuItem, IconButton ,ListItemIcon,ListItemText} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from '../../../../redux/store';

// components
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

UserMoreMenuProfile.propTypes = {
  onPause: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserMoreMenuProfile(props) {

  const { viewJobPost, EditJobPost,onPreview , onEdit, onDelete, onPause, EnableJobSeek , unPublishJobPost,onCloseFunction, jobType,jobStatus,jobPublish} = props;
  const { updateJobPostPausedata , updateUnPublishSuccData } = useSelector((state) => {
    return {
      updateJobPostPausedata: state && state.myjob && state.myjob.updateJobPostingPaused && state.myjob.updateJobPostingPaused.status ,
      updateUnPublishSuccData: state && state.myjob && state.myjob.updateUnPublishSucc && state.myjob.updateUnPublishSucc.status , 
    };
  });
  const [open, setOpen] = useState(null);
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if(updateJobPostPausedata === 200)
    {
      setOpen(null);
    }
  }, [updateJobPostPausedata]);

  useEffect(() => {
    if(updateUnPublishSuccData === 200)
    {
      setOpen(null);
    }
  }, [updateUnPublishSuccData]);

  

const HandleViewJobPost =() =>{
  viewJobPost()
  handleClose()
}
const HandlePreview = () =>{
    onPreview()
    handleClose()
}
const HandleEditJobPost= () =>{
  EditJobPost()
  handleClose()
}
const HandleSeek =() =>{
  EnableJobSeek()
  handleClose()
}
const HandleEdit = (e) =>{
  onEdit(e)
  handleClose()
}
const HandleDelete = (e) =>{
  onDelete(e)
  handleClose()
}

const HandleUnPublishJobPost = () =>{
  unPublishJobPost()
  handleClose()
} 
  return (
    <>
    <Tooltip title="Menu" >
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} sx={{color:"#000000"}} />
      </IconButton>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >  
        <MenuItem value="" onClick={HandleEdit}>
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:edit-outline'} width={20} height={20} sx={{color:"#000000"}} />
          </ListItemIcon>
          <ListItemText primary="Edit " />
        </MenuItem>
        
         <MenuItem value="" onClick={HandlePreview}>
          <ListItemIcon className='width-icon' >
            <Iconify icon={'eva:eye-outline'} width={20} height={20} sx={{color:"#000000"}} />
          </ListItemIcon>
          <ListItemText primary="Preview" />
        </MenuItem>
        <MenuItem value="" onClick={HandleDelete} sx={{ color: 'error.main' }}>
          <ListItemIcon className='width-icon'>
            <Iconify icon={'eva:trash-2-outline'} width={20} height={20} sx={{color:"error.main"}} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
       
      </MenuPopover>
    </>
  );
}
