import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    departmentsSuccResp: [],
    templateSuccResp: [],
    addTempSuccResp: [],
    getSaveTeamSettingAccessResp:[],
    updateRoleSuccResp: [],
    getAccessDepartmentResp:[],
    DeleteTeamAccessDepartResp:[],
    deleteRoleSuccResp: [],
    publishTeamSuccResp: [],
    SaveAccessDepartmentResp:[],
    getTeamAccessSettingResp : [],
    printableDataSuccResp: [],
    SaveSettingTeamAccessResp : [],
    BudgetSuccResp: [],
    DeleteAccessDepartResp : [],
    TeamSuccResp: [],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CREATE EVENT
        getTeamAccessDataSuccess(state, action) {
            state.isLoading = false;
            state.getTeamAccessResp = action.payload;
        },
        // CREATE EVENT
        getSaveTeamAccessSuccess(state, action) {
            state.isLoading = false;
            state.getSaveTeamAccessResp = action.payload;
        },
        // CREATE EVENT
        getTeamSettingSaveAccessSuccess(state, action) {
            state.isLoading = false;
            state.getTeamSettingAccessResp = action.payload;
        },
        // get Single Teammate
        getTeamSettingAccessDataSuccess(state, action) {
            state.isLoading = false;
            state.getSaveTeamSettingAccessResp = action.payload;
        },
        // Save Teammate (Single)
        SaveTeamSettingAccessDataSucc(state, action) {
            state.isLoading = false;
            state.SaveSettingTeamAccessResp = action.payload;
        },
        // Save Teammate Department (Single)
        getSettingTeamAccessDataSucc(state, action) {
            state.isLoading = false;
            state.getTeamAccessSettingResp = action.payload;
        },
        // CREATE EVENT
        getAccessDepartmentSuccess(state, action) {
            state.isLoading = false;
            state.getAccessDepartmentResp = action.payload;
        },
        // CREATE EVENT
        SaveAccessDepartmentSuccess(state, action) {
            state.isLoading = false;
            state.SaveAccessDepartmentResp = action.payload;
        },
        // CREATE EVENT
        DeleteAccessDepartmentSuccess(state, action) {
            state.isLoading = false;
            state.DeleteAccessDepartResp = action.payload;
        },
        DeleteTeamAccessDepartmentSuccess(state, action) {
            state.isLoading = false;
            state.DeleteTeamAccessDepartResp = action.payload;
        },
        // CREATE EVENT
        FormOptionSuccess(state, action) {
            state.isLoading = false;
            state.FormOptionResp = action.payload;
        },
        // CREATE EVENT
        ConnectAccessSuccess(state, action) {
            state.isLoading = false;
            state.ConnectAccessResp = action.payload;
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },


    },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------


export function TeamSettingSaveAccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/team_setting/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getTeamSettingSaveAccessSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function getTeamSettingAccessData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_team_setting/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getTeamSettingAccessDataSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function SaveTeamSettingAccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/access_team_department/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.SaveTeamSettingAccessDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function getTeamSettingAccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_access_team_departments/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getSettingTeamAccessDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function SaveTeamAccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/department_setting/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getSaveTeamAccessSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function getTeamAccessData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_department_setting/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getTeamAccessDataSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function SaveAccessDepartment(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/access_department/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.SaveAccessDepartmentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function getAccessDepartmentsData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_access_departments/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getAccessDepartmentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function DeleteAccessDepartmentsData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_access_department/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.DeleteAccessDepartmentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function DeleteTeamAccessDepartments(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_access_team_department/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.DeleteTeamAccessDepartmentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

export function getFormOptionsData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_departments_form_options/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.FormOptionSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function SaveConnectAccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/save_connect_team/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.ConnectAccessSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }

        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearConnectAccessError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.ConnectAccessSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearDeleteAccessDepartmentError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.DeleteAccessDepartmentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function ClearTeamDeleteAccessDepartmentError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.DeleteTeamAccessDepartmentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ClearSaveAccessDepartmentError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.SaveAccessDepartmentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ClearSaveTeamAccessDepartmentError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.SaveTeamSettingAccessDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearAccessDepartmentError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getAccessDepartmentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function CleargetTeamSettingAccessError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getTeamSettingAccessDataSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearSaveTeamSettingAccessError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getTeamSettingSaveAccessSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function CleargetTeamAccessError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getTeamAccessDataSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearSaveTeamAccessError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getSaveTeamAccessSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearHasError() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



