import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import TeamDashboardLayout from "../layouts/teamdashboard";
import ProjectLayout from "../layouts/project";
import JobLayout from "../layouts/jobs";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/")} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      // path: 'auth',
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            // <GuestGuard>
            //   <Register />
            // </GuestGuard>
            <Register />
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "reset-confirm-password", element: <ResetConfirmPassword /> },
        { path: "verify", element: <VerifyCode /> },
        { path: "project-preview", element: <ProjectPreview /> },
      ],
    },
    // User Profile
    // {
    //   path: "/:userId",
    //   children: [
    //     {
    //       path: "",
    //       element: <LocationDetailsPage />,
    //     },
    //   ],
    // },
    {
      path: "/:userId",
      children: [
        { path: "", element: <LocationDetailsPage /> },
        { path: "services", element: <ServicesLd /> },
        { path: "projects", element: <ProjectsLd /> },
        { path: "jobs", element: <JobsLd /> },
        { path: "new_updates", element: <NewUpdatesLd /> },
        { path: "gallery", element: <GalleryLd /> },
        { path: ":projectId", element: <ProjectDetailsPage /> },
        // { path: ":empEncId/:projectId", element: <ProjectDetailsPage /> },
      ],
    },
    {
      path: "/:empEncId/:projectId",
      children: [
        {
          path: "",
          element: <ProjectDetailsPage />,
        },
      ],
    },
    // Dashboard Routes
    {
      // path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "profile", element: <ProfilePageMain /> },
        {
          path: "jobs",
          children: [
            {
              element: <Navigate to="/jobs/postings/company" replace />,
              index: true,
            },
            // { path: 'postings', element: <JobCreate /> },
            { path: "postings/company", element: <JobPostingCompany /> },
            { path: "postings/project", element: <JobPostingProject /> },
            { path: "postings/archived", element: <JobPostingArchived /> },

            { path: "create", element: <JobMain /> },
            { path: "edit", element: <JobEdit /> },
          ],
        },
        // {
        //   path: 'setting',
        //   element: <SettingPageMain />,
        // },
        {
          path: "setting",
          children: [
            {
              element: <Navigate to="/setting/companyProfile" replace />,
              index: true,
            },
            // { path: 'insProfile', element: <SettingPageMain /> },
            { path: "companyProfile", element: <SettingProfile /> },
            { path: "mea_category", element: <SettingMEA /> },
            { path: "account", element: <SettingAccount /> },
            { path: "notification", element: <SettingNotification /> },
            {
              path: "profileVisibility",
              element: <SettingProfileVisibility />,
            },
            { path: "deleteAccount", element: <SettingDeleteAccount /> },
            { path: "manageAccount", element: <SettingManageAccount /> },
            // { path: 'subscription/:name', element: <SubscriptionPlans /> },
            { path: "subscription", element: <SubscriptionPlans /> },
            // { path: 'subscription/plans', element: <SubscriptionPlans /> },
            // { path: 'subscription/subscriptionTable', element: <SubscriptionTable /> },
            { path: "subscription/billingInfo", element: <BillingInfo /> },
            {
              path: "subscription/invoiceDetails/:InvId",
              element: <InvoiceDetails />,
            },
            // { path: 'subscription/invoiceDetails', element: <InvoiceDetails /> },
            { path: "paymentHistory", element: <SettingPayment /> },
          ],
        },
        {
          path: "wallet",
          children: [
            { element: <Navigate to="/wallet/payouts" replace />, index: true },
            { path: "walletPage", element: <WalletPage /> },
            { path: "payouts", element: <Payouts /> },
            { path: "recharge", element: <Recharges /> },
          ],
        },
        {
          path: "contacts",
          element: <MyContactPageMain />,
        },
        {
          path: "dashboard",
          element: <GeneralApp />,
        },
        {
          path: "talent",
          children: [
            { element: <Navigate to="/talent" replace />, index: true },
            { path: "search", element: <TalentSearchMains /> },
            {
              path: "search/:flag/:searchParameters",
              element: <ListOfTalent />,
            },
            {
              path: "talentSearchMain/:flag/:searchId/:searchParameters",
              element: <SavedSearchShow />,
            },
          ],
        },
        {
          path: "search",
          children: [
            {
              element: <Navigate to="/search/talentSearch" replace />,
              index: true,
            },
            { path: "talentSearch", element: <TalentSearchMains /> },
            {
              path: "talentSearchResult/:flag/:searchParameters",
              element: <ListOfTalent />,
            },
            {
              path: "talentSearchMain/:flag/:searchId/:searchParameters",
              element: <SavedSearchShow />,
            },
          ],
        },
        {
          path: "/activity-log",
          element: <ActivityLogPage />,
        },
        {
          path: "network",
          children: [
            // { path: "followers", element: <Followers /> },
            // { path: "following", element: <Following /> },
            // { path: "myinvitation", element: <MyInvitation /> },
            { path: "mynetwork", element: <MyNetwork /> },
            { path: "network", element: <Network /> },
            {
              path: "myinvitation",
              children: [
                { element: <Navigate to="/network/myinvitation/link" replace />, index: true },
                { path: "link", element: <InviteSocial /> },
                { path: "email", element: <InviteEmail /> },
                { path: "bulk", element: <InviteBulk /> },
                { path: "status", element: <InviteStatus /> },

              ],
            },
            {
              path: "followers",
              children: [
                { element: <Navigate to="/network/followers/talents" replace />, index: true },
                { path: "talents", element: <TalentsFollowers /> },
                { path: "companies", element: <CompaniesFollowers /> },
                { path: "institution", element: <InstitutionFollowers /> },

              ],
            },
            {
              path: "following",
              children: [
                { element: <Navigate to="/network/following/talents" replace />, index: true },
                { path: "talents", element: <TalentsFollowing /> },
                { path: "companies", element: <CompaniesFollowing /> },
                { path: "institution", element: <InstitutionFollowing /> },

              ],
            },
          ],
        },
        {
          path: "staff",
          children: [
            { path: "", element: <StaffTeams /> },

            {
              path: "createmember/:staffId/:slugName",
              element: <TeamMember />,
            },
          ],
        },
        {
          path: "project",
          children: [
            { path: "project-details", element: <ProjectDetails /> },
            { path: "photos", element: <ProjectPhotos /> },
            { path: "videos", element: <ProjectVideos /> },
            // { path: 'project', element: <ProjectView /> },
            { path: "project", element: <ProjectView /> },
            { path: "projectcreate", element: <ProjectCreate /> },
            // { path: 'teams', element: <Teams /> },
            // { path: 'createmember/:staffId/:slugName', element: <TeamMember /> },
            { path: "project-preview", element: <ProjectPreview /> },
            { path: "", element: <Projects /> },
            { path: "project-view", element: <ViewProject /> },
          ],
        },
        {
          path: "report",
          children: [
            {
              element: <Navigate to="/report/activity" replace />,
              index: true,
            },
            { path: "activity", element: <Activity /> },
          ],
        },
        { path: "calendar", element: <CalendarMain /> },
        {
          path: "inbox",
          children: [
            { path: "talent", element: <TalentInbox /> },
            { path: "talent/institution", element: <InstitutionInbox /> },
            { path: "talent/notification", element: <Notifications /> },
            { path: "talent/enquiry", element: <Enquiry /> },
          ],
        },
        // { path: 'website', element: <WebsiteAboutUs /> },
        { path: "subscription", element: <Subscription /> },
        {
          path: "website",
          children: [
            { element: <Navigate to="/website/about" replace />, index: true },
            { path: "about", element: <WebsiteAboutUs /> },
            { path: "banners", element: <WebsiteBanners /> },
            { path: "services", element: <WebsiteServices /> },
            { path: "projects", element: <WebsiteProject /> },
            { path: "theme", element: <WebsiteThemes /> },
            { path: "setting", element: <WebsiteSetting /> },
            { path: "enquiry", element: <WebsiteEnquiry /> },
            { path: "socialmedia", element: <WebsiteSocialMedia /> },
            { path: "gallery", element: <WebsiteGallery /> },
            {
              path: "gallery/image-upload",
              element: <WebsiteGalleryImageUpload />,
            },
            {
              path: "gallery/album-view",
              element: <WebsiteGalleryAlbumView />,
            },
          ],
        },
      ],
    },
    {
      path: "projects",
      element: <ProjectLayout />,
      children: [
        { path: ":projectid/dashboard", element: <ProjectDashboard /> },
        // { path: ':projectid/dashboard', element: <MainDashboard /> },
        {
          path: ":projectid/team_quick/:departtid",
          element: <QuickTemplate />,
        },
        {
          path: ":projectid/team_details/:departtid/:roletype",
          element: <DetailedTemplate />,
        },
        {
          path: ":projectid/team/team_quick/:departtid",
          element: <TeamsQuick />,
        },
        {
          path: ":projectid/team/team_details/:departtid/:roletype",
          element: <TeamsDetailed />,
        },
        { path: "projectdashboard", element: <ProjectDashboard /> },
        { path: "projectdepartments", element: <ProjectDepartments /> },
        { path: ":projectid/newsupdate", element: <NewsUpdate /> },
        { path: ":projectid/projectsettings", element: <ProjectSettings /> },
        { path: ":projectid/budget", element: <Budget /> },
        { path: ":projectid/wages", element: <Wages /> },
        { path: ":projectid/timeline", element: <TimeLine /> },
        { path: ":projectid/reports", element: <Reports /> },
        { path: ":projectid/reports/:Rid", element: <ReportDetailed /> },
        { path: ":projectid/jobs", element: <ProjectJobs /> },
        { path: ":projectid/team", element: <ProjectTeam /> },
        { path: ":projectid/remuneration", element: <Remuneration /> },
        {
          path: ":projectid/remuneration-detailed",
          element: <RemunerationDetailed />,
        },
        // { path: ':projectid/remuneration-invoice', element: <RemunerationInvoice /> },

        {
          path: ":projectid/:InvID/remuneration-invoice/:deptID",
          element: <RemunerationInvoice />,
        },
        // { path: ':projectid/teamDashboard/:rowId/:deptID', element: <TeamDashboard /> },
        // { path: ':projectid/Payments/:rowId/:deptID', element: <TeamPayment /> },
        // { path: ':projectid/Connect/:rowId/:deptID', element: <TeamConnect /> },
        // { path: ':projectid/teamReports/:rowId/:deptID', element: <TeamReports /> },
        // { path: ':projectid/TeamSettings/:rowId/:deptID', element: <TeamSetting /> },
      ],
    },
    {
      path: "projects",
      element: <TeamDashboardLayout />,
      children: [
        {
          path: ":projectid/teamdashboard/:rowId/:deptID",
          element: <TeamDashboard />,
        },
        {
          path: ":projectid/payments/:rowId/:deptID",
          element: <TeamPayment />,
        },
        { path: ":projectid/connect/:rowId/:deptID", element: <TeamConnect /> },
        {
          path: ":projectid/teamreports/:rowId/:deptID",
          element: <TeamReports />,
        },
        {
          path: ":projectid/teamsettings/:rowId/:deptID",
          element: <TeamSetting />,
        },
      ],
    },
    {
      path: "jobs",
      element: <JobLayout />,
      children: [
        { path: "Dashboard/:jobId", element: <ViewJobDashboard /> },
        { path: "Candidates/:jobId", element: <ViewCandidateList /> },
        { path: "Messages/:jobId", element: <JobDashboardMessage /> },
        { path: "Interviews/:jobId", element: <Interviews /> },
        { path: "Jobseek/:jobId", element: <JobseekView /> },
        { path: "reports/:jobId", element: <JobReport /> },
        { path: "reports/:jobId/:typeId", element: <JobReportDetail /> },
        { path: "timeline/:jobId", element: <JobTimeline /> },
        { path: "activity/:jobId", element: <JobActivityLog /> },
        {
          path: "ViewCandidate/:jobId/:candidateId",
          element: <ViewCandidate />,
        },
        { path: "settings/:jobId", element: <JobPostingsSettings /> },
        { path: "jobCreate", element: <JobOfficeCreate /> },
        { path: "JobOfficePreview/:jobId", element: <JobOfficePreview /> },
        { path: "jobProjectCreate/:projectId", element: <JobProjectCreate /> },
        { path: "jobProjectEdit/:jobId", element: <JobProjectEdit /> },
        { path: "jobCompanyEdit/:jobId", element: <JobCompanyEditPage /> },
        { path: "castJobPosting/:jobId", element: <CastJobPosting /> },
        { path: "crewJobPosting/:jobId", element: <CrewJobPosting /> },
        { path: "jobProjectList", element: <JobProjectList /> },
        {
          path: "projectcastjobcreate/:ProId/:roleId",
          element: <ProjectJobCastCreatePage />,
        },
        {
          path: "projectcrewjobcreate/:ProId/:roleId",
          element: <ProjectJobCrewCreatePage />,
        },
      ],
    },

    {
      path: "/",
      element: <Login />,
      children: [{ element: <HomePage />, index: true }],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const ResetConfirmPassword = Loadable(
  lazy(() => import("../pages/auth/ResetConfirmPass"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const PortFolio = Loadable(lazy(() => import("../pages/ComingSoon")));
// Main
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const LocationDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetails"))
);
const ServicesLd = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetailsChildren/Services/ServicesLdMain"))
);
const ProjectsLd = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetailsChildren/Project/ProjectMain"))
);
const JobsLd = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetailsChildren/JobsLd"))
);
const NewUpdatesLd = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetailsChildren/NewUpdatesLd"))
);
const GalleryLd = Loadable(
  lazy(() => import("../pages/dashboard/LocationDetailsChildren/Gallery/GalleryMain"))
);

// const Templates = Loadable(lazy(() => import('../pages/dashboard/templates/ThemeProfile')));
// const Resumes = Loadable(lazy(() => import('../pages/dashboard/templates/Resumes')));
// const CoverLetter = Loadable(lazy(() => import('../pages/dashboard/templates/CoverLetter')));

const SettingPageMain = Loadable(
  lazy(() => import("../pages/dashboard/SettingPage"))
);
const MyContactPageMain = Loadable(
  lazy(() => import("../pages/dashboard/MyContact"))
);

const CalendarMain = Loadable(
  lazy(() => import("../pages/dashboard/Calendar"))
);

const ProfilePageMain = Loadable(
  lazy(() => import("../pages/dashboard/EmployerPage"))
);
const TalentSearchMains = Loadable(
  lazy(() => import("../pages/dashboard/TalentSearchPage"))
);
const ListOfTalent = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/SavedTalentAdvancedSearch")
  )
);
const JobMain = Loadable(lazy(() => import("../pages/dashboard/JobCreate")));
const SavedSearchShow = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/SavedTalentAdvancedSearch")
  )
);
const JobCreate = Loadable(lazy(() => import("../pages/dashboard/JobsPage")));

const JobOfficeCreate = Loadable(
  lazy(() => import("../pages/dashboard/JobCreate"))
);
const JobProjectCreate = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/CreatePage"))
);
const JobProjectEdit = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/EditPage"))
);
const JobPostingCompany = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/JobInterviews"))
);
const JobPostingArchived = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/JobArchived"))
);
const JobPostingProject = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/JobPostings"))
);
const JobCompanyEditPage = Loadable(
  lazy(() => import("../pages/dashboard/EditOfficeJob"))
);
const JobProjectList = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/JobProjectsList"))
);
const JobOfficePreview = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/jobOfficePreview"))
);
const ViewProject = Loadable(
  lazy(() => import("../pages/dashboard/ProjectView"))
);
const ProjectCreate = Loadable(
  lazy(() => import("../pages/dashboard/projects/CreateProject"))
);
const CastJobPosting = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/CastJobPosting"))
);
const CrewJobPosting = Loadable(
  lazy(() => import("../pages/dashboard/JobsSection.js/CrewJobPosting"))
);
const ProjectJobCastCreatePage = Loadable(
  lazy(() =>
    import("../pages/dashboard/JobsSection.js/ProjectJobCastCreatePage")
  )
);
const ProjectJobCrewCreatePage = Loadable(
  lazy(() =>
    import("../pages/dashboard/JobsSection.js/ProjectJobCrewCreatePage")
  )
);

const JobEdit = Loadable(lazy(() => import("../pages/dashboard/JobsEditPage")));
const JobPostingsSettings = Loadable(  lazy(() => import("../pages/dashboard/JobPostingSettings/Setting")));
const Followers = Loadable(  lazy(() => import("../pages/dashboard/connectnetwork/Followers")));
const TalentsFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Talents")));
const CompaniesFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Companies")));
const InstitutionFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Institutions")));
const TalentsFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnTalents")));
const CompaniesFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnCompanies")));
const InstitutionFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnInstitutions")));
const Following = Loadable(  lazy(() => import("../pages/dashboard/connectnetwork/Following")));
const Network = Loadable(  lazy(() => import("../pages/dashboard/connectnetwork/Network")));
const MyNetwork = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyNetwork")));
const MyInvitation = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyInvitation")));
const InviteSocial = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyInvitation/InviteSocial")));
const InviteEmail = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyInvitation/InviteEmail")));
const InviteBulk = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyInvitation/InviteBulk")));
const InviteStatus = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/MyInvitation/InviteStatus")));
// const WebsiteAboutUs = Loadable(lazy(() => import('../pages/dashboard/Website/AboutUs')));
const WebsiteAboutUs = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/EmployerOrganisation"))
);
const WebsiteBanners = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/EmployerOrgBanners"))
);
const WebsiteServices = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/EmployerServices"))
);
const WebsiteProject = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/EmployerProjects"))
);
const WebsiteThemes = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/EmployerWebsiteThemes")
  )
);
const WebsiteSocialMedia = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/EmployerWebsiteSocialMedia")
  )
);
const WebsiteSetting = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/EmployerWebsiteSettings")
  )
);
const WebsiteEnquiry = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/profile/EmployerWebsiteEnquiries")
  )
);
const WebsiteGallery = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/UserGallery"))
);
const WebsiteGalleryImageUpload = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/WebsiteGallery/GalleryImageUpload")
  )
);
const WebsiteGalleryAlbumView = Loadable(
  lazy(() =>
    import("../sections/@dashboard/user/WebsiteGallery/GalleryAlbumView")
  )
);

const TalentInbox = Loadable(
  lazy(() => import("../pages/dashboard/InBox/TalentInbox"))
);
const Subscription = Loadable(
  lazy(() => import("../pages/dashboard/InBox/Subscription"))
);
const Notifications = Loadable(
  lazy(() => import("../pages/dashboard/InBox/Notifications"))
);
const Enquiry = Loadable(
  lazy(() => import("../pages/dashboard/InBox/Enquiry"))
);
const InstitutionInbox = Loadable(
  lazy(() => import("../pages/dashboard/InBox/InstitutionInbox"))
);
const Activity = Loadable(
  lazy(() => import("../pages/dashboard/Reports/Activity"))
);

const ProjectDetails = Loadable(
  lazy(() => import("../pages/dashboard/UserProfileProjects"))
);
const ProjectPhotos = Loadable(
  lazy(() => import("../pages/dashboard/UserPhotosProject"))
);
const ProjectVideos = Loadable(
  lazy(() => import("../pages/dashboard/UserVideoProject"))
);
const ProjectView = Loadable(
  lazy(() => import("../pages/dashboard/ProjectAddPage"))
);

const ProjectPreview = Loadable(
  lazy(() => import("../pages/dashboard/PreviewProject"))
);
const MainDashboard = Loadable(
  lazy(() => import("../pages/dashboard/projects/MainDashboard"))
);

const ProjectDashboard = Loadable(
  lazy(() => import("../pages/dashboard/projects/ProjectDashboard"))
);
const ProjectDepartments = Loadable(
  lazy(() => import("../pages/dashboard/projects/ProjectDepartments"))
);
const NewsUpdate = Loadable(
  lazy(() => import("../pages/dashboard/projects/NewsUpdate"))
);
const Wages = Loadable(lazy(() => import("../pages/dashboard/projects/Wages")));
const ProjectSettings = Loadable(
  lazy(() => import("../pages/dashboard/projects/ProjectSettingPage"))
);
// const ProjectSettings = Loadable(lazy(() => import('../pages/dashboard/projects/ProjectSettings')));
const Budget = Loadable(
  lazy(() => import("../pages/dashboard/projects/Budget"))
);
const TimeLine = Loadable(
  lazy(() => import("../pages/dashboard/projects/TimeLine"))
);
const Reports = Loadable(
  lazy(() => import("../pages/dashboard/projects/Reports"))
);
const ReportDetailed = Loadable(
  lazy(() => import("../pages/dashboard/projects/ReportDetailPage"))
);
const ProjectJobs = Loadable(
  lazy(() => import("../pages/dashboard/projects/Jobs"))
);
const ProjectTeam = Loadable(
  lazy(() => import("../pages/dashboard/projects/DefaultTeam"))
);
const QuickTemplate = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsBudgets/QuickTemplate/QuickTemplate"
    )
  )
);
const DetailedTemplate = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsBudgets/DetailedTemplate/DetailedTemplate"
    )
  )
);
// Teams
const TeamsQuick = Loadable(
  lazy(() =>
    import("../pages/dashboard/projects/teamsProjects/QTemplate/QuickTemplate")
  )
);
const TeamsDetailed = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/DTemplate/DetailedTemplate"
    )
  )
);

const TeamDashboard = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/TeamMainDashboard/TeamDashboard"
    )
  )
);
const TeamPayment = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/TeamMainDashboard/TeamPayment"
    )
  )
);
const TeamConnect = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/TeamMainDashboard/TeamConnect"
    )
  )
);
const TeamReports = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/TeamMainDashboard/TeamReports"
    )
  )
);
const TeamSetting = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/projects/teamsProjects/TeamMainDashboard/TeamSetting"
    )
  )
);

const Remuneration = Loadable(
  lazy(() => import("../pages/dashboard/projects/Remuneration"))
);
const RemunerationDetailed = Loadable(
  lazy(() =>
    import("../pages/dashboard/projects/Remuneration/RemunerationDetailed")
  )
);
const RemunerationInvoice = Loadable(
  lazy(() => import("../pages/dashboard/projects/Remuneration/Invoice"))
);

const StaffTeams = Loadable(
  lazy(() => import("../pages/dashboard/projects/Teams"))
);
const StaffCreate = Loadable(
  lazy(() => import("../pages/dashboard/projects/CreateMember"))
);
const CreateProject = Loadable(
  lazy(() => import("../pages/dashboard/projects/CreateProject"))
);
const Projects = Loadable(
  lazy(() => import("../pages/dashboard/projects/Projects"))
);
const TeamMember = Loadable(
  lazy(() => import("../pages/dashboard/projects/CreateTeam"))
);

const ViewJobs = Loadable(lazy(() => import("../pages/dashboard/ViewJobs")));
const ViewJobDashboard = Loadable(
  lazy(() => import("../pages/dashboard/ViewJobDashboard"))
);
const ViewCandidateList = Loadable(
  lazy(() => import("../pages/dashboard/ViewCandidateList"))
);
const JobDashboardMessage = Loadable(
  lazy(() => import("../pages/dashboard/JobMessage"))
);
const ViewCandidate = Loadable(
  lazy(() => import("../pages/dashboard/ViewCandidate"))
);
const Interviews = Loadable(
  lazy(() => import("../pages/dashboard/Interviews"))
);
const JobseekView = Loadable(
  lazy(() => import("../pages/dashboard/JobseekView"))
);
const JobReport = Loadable(lazy(() => import("../pages/dashboard/JobReport")));
const JobReportDetail = Loadable(
  lazy(() => import("../pages/dashboard/JobReportDetail"))
);

const JobTimeline = Loadable(
  lazy(() => import("../pages/dashboard/JobTimeline"))
);
const JobActivityLog = Loadable(
  lazy(() => import("../pages/dashboard/JobActivityLog"))
);
const ProjectDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/ProjectDetails"))
);
const ActivityLogPage = Loadable(
  lazy(() => import("../pages/dashboard/activitylog/ActivityLogPage"))
);

// ------------------------------------------
const SettingProfile = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/CompanyProfile"))
);
const SettingMEA = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/MEACategorySetting"))
);
const SettingAccount = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/Account"))
);
const SettingNotification = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/Notification"))
);
const SettingManageAccount = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/ManageAccount"))
);
const SettingDeleteAccount = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/DeleteAccount"))
);
const SettingProfileVisibility = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/ProfileVisibility"))
);

const SubscriptionPlans = Loadable(
  lazy(() => import("../pages/dashboard/subscription/Subscription"))
);
const BillingInfo = Loadable(
  lazy(() => import("../pages/dashboard/subscription/BillingInfo"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/subscription/InvoiceDetails"))
);
const SubscriptionTable = Loadable(
  lazy(() => import("../pages/dashboard/subscription/SubscriptionTable"))
);
const SettingPayment = Loadable(
  lazy(() => import("../sections/@dashboard/user/profile/PaymentHistory"))
);
const WalletPage = Loadable(
  lazy(() => import("../pages/dashboard/Wallet/WalletPage"))
);
const Payouts = Loadable(
  lazy(() => import("../pages/dashboard/Wallet/Payouts"))
);
const Recharges = Loadable(
  lazy(() => import("../pages/dashboard/Wallet/Recharges"))
);
