import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
// assets
import { LoadingButton } from '@mui/lab';
import { UploadIllustration } from '../../assets';
import iconImage from '../../images/image-icon.png';

const BlockCover = () => {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Box
        component="img"
        sx={{
          // width: 350,
          maxHeight: { xs: 95, md: 95 },
          maxWidth: { xs: 107, md: 107 },
        }}
        alt="The house from the offer."
        src={iconImage}
      />

      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography sx={{ mb: 0.75, color: '#44577a', fontSize: 14 }}>
          Add Poster Image <br /> Size 1900 x 600 Pixels
        </Typography>
        <Box sx={{}}>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
        </Box>
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
      </Box>
    </Stack>
  );
};

export default BlockCover;
