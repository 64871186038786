import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Link,
  TextField,
  Typography, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
//
import { LoadingButton } from '@mui/lab';
import EditProfileImageModel from '../../pages/dashboard/Modals/EditProfileImageModel';
import PreviewModal from '../../pages/dashboard/Modals/PreviewModal';
import LightboxModal from '../LightboxModal';
import Image from '../ImageTwo';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import AwardsBlock from './AwardsBlock';
import AddBlock from './AddBlock';
import { UserMoreMenuProfile } from '../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../redux/store';
import { getProfileData, deleteProfileData, ClearUpdateProfileProfile } from '../../redux/slices/companyProfile';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(4, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: '#ffffff',
  // border: `1px dashed ${theme.palette.grey[500_32]}`,

  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFileAdd.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFileAdd({ error = false, Imgdescription, className, file, helperText, sx, ...other }) {
  const dispatch = useDispatch();
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  const [photoLightBox, setphotoLightBox] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);


  useEffect(() => {
    if (file && file.length > 0) {
      setphotoLightBox([...photoLightBox, file]);
    }
  }, [file])

  useEffect(() => {
    if (Imgdescription && Imgdescription !== undefined) {
      console.log(file, Imgdescription, 'Imgdescription')
    }
  }, [Imgdescription])


  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [previewvaldata, setPreviewvaldata] = useState("");
  const handlePreviewUser = (file) => {
    const selectedImage = photoLightBox.findIndex((index) => index === file);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };
  const handleClosePreviewVModal = (value) => {
    setOpenPreviewModal(false);
  };

  const handleDeleteUser = (data) => {
    setOpenDeleteModal(true);
    // const deleteObj = {
    //   position: className,
    // }
    // dispatch(deleteProfileData(deleteObj))
  };

  const handleEditUser = (data) => {
    setOpen(true)
  };

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };
  const openModelCall = () => {
    setOpen(true)
  }

  const handleDeleteUserConfirm = (data) => {
    setOpenDeleteModal(false);
    const deleteObj = {
      position: className,
    }
    dispatch(deleteProfileData(deleteObj))
  };

  const handleDeleteUserClose = (data) => {
    setOpenDeleteModal(false);
  };

  return (
    <Box sx={{ width: '100%', ...sx }} className="image-selected">
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '13% 0',
          }),
          // border: '2px dashed grey',
          border: file && file.length === 0 ? '2px dashed #ccc' : 'none'
        }}
      >
        {file && file.length === 0 ? <input {...getInputProps()} /> : <input {...getInputProps()} disabled />}

        <AddBlock />

        {/* {file && (
          <Box>
            <Image
              alt="file preview"
              src={isString(file) ? file : file && file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
              }}
            />
          </Box>
        )} */}
        {file && file.length === 0 ? (
          <Box>
            {/* <Image
              alt="file preview"
              src={isString(file) ? file : file && file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
              }}
            />  */}
          </Box>
        ) : (
          <Box>
            <Image
              alt="file preview"
              src={isString(file) ? file : file && file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
              }}
            />
            <Box className="icon-award1">
              <IconButton>
                <UserMoreMenuProfile
                  onDelete={() => handleDeleteUser(file)}
                  userName={'name'}
                  onEdit={(e) => handleEditUser(file)}
                  onPreview={(e) => handlePreviewUser(file)}
                />
              </IconButton>
            </Box>
          </Box>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}

      <EditProfileImageModel
        selectedValue={selectedValue}
        currentUser={file}
        classIndex={className}
        openn={open}
        onClose={handleClose}
      />
      <LightboxModal
        images={photoLightBox}
        mainSrc={photoLightBox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
      <PreviewModal
        selectedValue={''}
        open={openPreviewModal}
        onClose={handleClosePreviewVModal}
        currentUser={previewvaldata}
      />

      <Dialog
        open={openDeleteModal}
        keepMounted
        onClose={handleDeleteUserClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {"Are you sure you want to delete? "}
        </DialogTitle>
        <DialogActions>
          <LoadingButton
            onClick={handleDeleteUserConfirm}
            type="button"
            variant="contained"
          >
            Yes
          </LoadingButton>
          <LoadingButton
            // sx={{ ml: "10px", mr: "35px", backgroundColor: "#d3e1ea" }}
            onClick={handleDeleteUserClose}
            variant="outlined"
            type="button"
          >
            No
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
