import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  projDetailsData: [],
  getPreviewProjectDataResp: [],
  projEditData: '',
  PostProjDetData: [],
  editPostProjDetData: [],
  UpdateVideoRes: [],
  publishWebProjectSucc: [],
  getProjectDetailsSucc: [],
  enquiryData: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  AddProjectShow: '',
  addProfilePhotos: '',
  deleteProPhotosSucc: '',
  deleteProjectSucc: '',
  profileServiceUpdate: '',
  ProjectVideosUpdate: '',
  getProjectPreviewId: '',
  projectPreviewBack: '',
  companyPreview: [],
  sendEnquirySucc: '',
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getProjDetailsSucc(state, action) {
      state.isLoading = false;
      state.projDetailsData = action.payload;
    },

    getProjEditSucc(state, action) {
      state.isLoading = false;
      state.projEditData = action.payload;
    },
    postProjDetailsSucc(state, action) {
      state.isLoading = false;
      state.PostProjDetData = action.payload;
    },

    editPostProjDetailsSucc(state, action) {
      state.isLoading = false;
      state.editPostProjDetData = action.payload;
    },
    AddProjectShowFormSucc(state, action) {
      state.isLoading = false;
      state.AddProjectShow = action.payload;
    },

    addProfilePhotosSuccess(state, action) {
      state.isLoading = false;
      state.addProfilePhotos = action.payload;
    },

    deleteProfilePhotosSucc(state, action) {
      state.isLoading = false;
      state.deleteProPhotosSucc = action.payload;
    },

    deleteProjectSuccData(state, action) {
      state.isLoading = false;
      state.deleteProjectSucc = action.payload;
    },

    PublishWebProjectSuccData(state, action) {
      state.isLoading = false;
      state.publishWebProjectSucc = action.payload;
    },

    ProfileServiceUpdateSuccess(state, action) {
      state.isLoading = false;
      state.profileServiceUpdate = action.payload;
    },
    UpdateVideoSuccess(state, action) {
      state.isLoading = false;
      state.UpdateVideoRes = action.payload;
    },

    addProjectVideosSuccess(state, action) {
      state.isLoading = false;
      state.ProjectVideosUpdate = action.payload;
    },

    getProjectPreviewIdSucc(state, action) {
      state.isLoading = false;
      state.getProjectPreviewId = action.payload;
    },

    getPreviewProjectDataSucc(state, action) {
      state.isLoading = false;
      state.getPreviewProjectDataResp = action.payload;
    },

    getPreviewProjectBackSucc(state, action) {
      state.isLoading = false;
      state.projectPreviewBack = action.payload;
    },
    getEnquirySucc(state, action) {
      state.isLoading = false;
      state.enquiryData = action.payload;
    },

    getCompanyPreviewSucc(state, action) {
      state.isLoading = false;
      state.companyPreview = action.payload;
    },
    getLocationProjectDetailsSucc(state, action) {
      state.isLoading = false;
      state.getProjectDetailsSucc = action.payload;
    },

    getSendEnquirySucc(state, action) {
      state.isLoading = false;
      state.sendEnquirySucc = action.payload;
    },

    // CREATE EVENT
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProjectDetailsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjDetailsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getProjectEditData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjEditSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

// export function getProjectDetailsData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/get_employer_projects/',data);
//       dispatch(slice.actions.getProjDetailsSucc(response.data));
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//   dispatch(slice.actions.hasError(error));
// } else{
//   dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
// }
//     }
//   };
// }

export function getPreviewProjectData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPreviewProjectDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
      // const response = await axios.post('/get_employer_projects/',data);
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function PostProjectDetailsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_website_project/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.postProjDetailsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function EditPostProjectDetailsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_website_project/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.editPostProjDetailsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getCompanyPreviewData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/company_profile_preview/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCompanyPreviewSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
      // const response = await axios.post('/get_employer_projects/',data);
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

// export function getEnquiryData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/get_enquiry/');
//       if (response && response.data && response.data.status === 200) {
//         dispatch(slice.actions.getEnquirySucc(response.data));
//       } else {
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     }
//   };
// }



export function getEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
      // const response = await axios.post('/get_employer_projects/',data);
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function AddProjectShowForm(data, id = '', photos = '', videos = '') {
  const dataValue = {
    "data": data,
    "id": id,
    "photos": photos,
    "videos": videos,
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = dataValue;
      dispatch(slice.actions.AddProjectShowFormSucc(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPreviewId(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = data;
      dispatch(slice.actions.getProjectPreviewIdSucc(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadProfilePhotos(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/upload_website_project_portfolio/', data, {
        headers: { 'Content-type': 'multipart/form-data' },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.addProfilePhotosSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function DeleteProfilePhotos(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_website_project_portfolio/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteProfilePhotosSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function DeleteProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/delete_website_project/${data}`);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteProjectSuccData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function PublishOnWebsiteProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/show_on_website/`, data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.PublishWebProjectSuccData(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function updateProfileServices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem('accessToken');
    try {
      const response = await axios.post('/update_website_project_portfolio/', data, {
        headers: { 'Content-type': 'multipart/form-data' },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileServiceUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}
export function updateProfileVideo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem('accessToken');
    try {
      const response = await axios.post('/update_website_project_portfolio/', data, {
        headers: { 'Content-type': 'multipart/form-data' },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UpdateVideoSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function uploadProjectVideos(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/upload_website_project_portfolio/', data, {
        headers: { 'Content-type': 'multipart/form-data' },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.addProjectVideosSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getPreviewProjectBack(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = data;
      dispatch(slice.actions.getPreviewProjectBackSucc(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSendEnquiry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSendEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearDeletePhotosData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteProfilePhotosSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearAddPhotosData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.addProfilePhotosSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearAddVideosData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.addProjectVideosSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearPostProjectDetailsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.postProjDetailsSucc([]));
      dispatch(slice.actions.AddProjectShowFormSucc([]));
      dispatch(slice.actions.addProfilePhotosSuccess([]));
      dispatch(slice.actions.deleteProjectSuccData([]));
      dispatch(slice.actions.getProjEditSucc([]));
      dispatch(slice.actions.editPostProjDetailsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearPublishWebProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.PublishWebProjectSuccData([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearEditProjectData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjEditSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearAddProjectShow(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.AddProjectShowFormSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearProfilePreview(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPreviewProjectBackSucc([]));
      dispatch(slice.actions.getPreviewProjectDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearUpdateProjectFiles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UpdateVideoSuccess([]));
      dispatch(slice.actions.ProfileServiceUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSendEnquiry() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSendEnquirySucc([]));
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLocationProjectDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_view_project/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getLocationProjectDetailsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
      // const response = await axios.post('/get_employer_projects/',data);
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}
