// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';
import iconImage from '../../images/image-icon.png';
// ----------------------------------------------------------------------

export default function BlockContentProj() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Box
        component="img"
        sx={{
          // width: 350,
          maxHeight: { xs: 95, md: 95 },
          maxWidth: { xs: 107, md: 107 },
        }}
        alt="The house from the offer."
        src={iconImage}
      />

      <Box sx={{ p: 1 }}>
        <Typography gutterBottom variant="h5">
          + Add Gallery Images
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' ,fontSize:"12px"}}>
          Gallery images size 1900 x 550 pixels <br/>
          (Max 20 images) <br/>
          Max Image Size 20MB
        </Typography>
      </Box> 
    </Stack>
  );
}