import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// import logo from '../assets/'
import Logo1 from '../images/TK-LOGO.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  const logo = (
    <Box sx={{ width: 200, ...sx }}>
      {/* <img src={Logos} alt="logo" />  */}
      <a href="https://talentkind.com/">
        <img src={Logo1} alt="logo" className="logoImage" />
      </a>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <a href="https://talentkind.com/" className='logiLoginHolder'>{logo}</a>;
}
