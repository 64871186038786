import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, AppBar, Toolbar, Badge, IconButton } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { PATH_DASHBOARD } from '../../../routes/paths';


const Wallet = () => {
    const navigate = useNavigate();

    const handleWalletClick = () => {

        navigate(PATH_DASHBOARD.wallet.payouts);
    }



    return (
        <div> <IconButton onClick={handleWalletClick}>

            <AccountBalanceWalletIcon color="action" sx={{ fontSize: '1.8rem' }} />

        </IconButton></div>
    )
}

export default Wallet