import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getMyJobPosting: [],
  updateJobPostingPaused: [],
  updateUnPublishSucc: [],
  getProjectResp: [],
  getPausedId: [],
  extendJobDateResp: [],
  getCommAndNotiResp: [],
  updateCommAndNotiResp: [],
  getDashboardDataResp: [],
  getScheduledInterviewsResp: [],
  asignProjectSucc: [],
  getJobSettingHelpResp: [],
  getJobReportsSuccResp: [],
  getJobDetailedReportsSuccResp: [],
  getJobActivitySuccResp: [],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    getMyJobPostingSucc(state, action) {
      state.isLoading = false;
      state.getMyJobPosting = action.payload;
    },

    saveHpbPostPausedSucc(state, action) {
      state.isLoading = false;
      state.updateJobPostingPaused = action.payload;
    },

    saveUnPublishSucc(state, action) {
      state.isLoading = false;
      state.updateUnPublishSucc = action.payload;
    },
    getProjectAsignSucc(state, action) {
      state.isLoading = false;
      state.asignProjectSucc = action.payload;
    },

    getPausedSucc(state, action) {
      state.isLoading = false;
      state.getPausedId = action.payload;
    },
    extendJobDateSucc(state, action) {
      state.isLoading = false;
      state.extendJobDateResp = action.payload;
    },
    getCommAndNotiSucc(state, action) {
      state.isLoading = false;
      state.getCommAndNotiResp = action.payload;
    },
    saveCommAndNotiSucc(state, action) {
      state.isLoading = false;
      state.updateCommAndNotiResp = action.payload;
    },
    getDashboardDataSucc(state, action) {
      state.isLoading = false;
      state.getDashboardDataResp = action.payload;
    },
    getScheduledInterviewsSucc(state, action) {
      state.isLoading = false;
      state.getScheduledInterviewsResp = action.payload;
    },
    getProjectSucc(state, action) {
      state.isLoading = false;
      state.getProjectResp = action.payload;
    },
    getProjectJobSettingHelpSucc(state, action) {
      state.isLoading = false;
      state.getJobSettingHelpResp = action.payload;
    },
    getJobReportsSucc(state, action) {
      state.isLoading = false;
      state.getJobReportsSuccResp = action.payload;
    },
    getDetailedJobReportsSucc(state, action) {
      state.isLoading = false;
      state.getJobDetailedReportsSuccResp = action.payload;
    },
    getJobActivitySucc(state, action) {
      state.isLoading = false;
      state.getJobActivitySuccResp = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getJobPosting(data = '') {
  return async () => {
    dispatch(slice.actions.startLoading());
    const newEvent = {
      job_id: '',
    }
    try {
      if (data === '') {
        const response = await axios.post('/get_job_posting/', newEvent);
        if (response && response.data && response.data.status === 200) {
          dispatch(slice.actions.getMyJobPostingSucc(response.data));
        } else {
          dispatch(slice.actions.hasError(response.data))
        }
      } else {
        const response = await axios.post('/get_job_posting/', data);
        if (response && response.data && response.data.status === 200) {
          dispatch(slice.actions.getMyJobPostingSucc(response.data));
        } else {
          dispatch(slice.actions.hasError(response.data))
        }
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function saveJobPostPause(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting_status/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveHpbPostPausedSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveJobPostUnPublish(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_posting_status/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveUnPublishSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getPausedJobPosting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const newEvent = {
      job_id: data,
    }
    try {
      const response = await axios.post('/get_job_posting/', newEvent);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPausedSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function saveJobPostUnPublish(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/job_posting_pause/',data);
//       dispatch(slice.actions.saveHpbPostPausedSucc(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }  



export function extendJobDate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/extend_apply_date/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.extendJobDateSucc(response.data));
      }
      else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getJobCommAndNotiList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_communication_setting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCommAndNotiSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveJobCommAndNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_communication_setting/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveCommAndNotiSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearsaveJobCommAndNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveCommAndNotiSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDashboardDataPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_dashboard/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getDashboardDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getScheduledInterviews(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_scheduled_interviews/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getScheduledInterviewsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAssignProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_assign_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectAsignSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearJobPostinggetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getMyJobPostingSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveHpbPostPausedSucc([]));
      dispatch(slice.actions.saveUnPublishSucc([]));
      dispatch(slice.actions.extendJobDateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSavePostPaused(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveHpbPostPausedSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProjectJobSettingHelp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_job_help/')
      // const response = await axios.post('/get_billing_info/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectJobSettingHelpSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getJobReports(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_reports/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobReportsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearGetJobReports(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJobReportsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getDetailedJobReports(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/job_report_summary/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getDetailedJobReportsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearGetDetailedJobReports(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getDetailedJobReportsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getJobActivity(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_timeline/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobActivitySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}




