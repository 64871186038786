import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  publishTeamDash: [],
  publishTeamPay: [],
  savePublishTeamPayment: [],
  publishTeamList: [],
  publishTeamSetting: [],
  publishTeamPaySetting: [],
  managePublistAccount: [],
  managePaymentSettingData: [],
  managePaymentModeSettingData: [],
  getPublishDeptList: [],
  getRemunerationProjDeptList: [],
  getTeamRemunerationList: [],
  getTalentUserResp: [],
  getPublishTeamSetting: [],
  saveAccessTeamDept: [],
  sendPublishTeamOtpData: [],
  saveRemunerationResp: [],
  getPaymentHistoryResp: {},
  getRemunerationInvResp: {},
  verifyPublish: [],
  talentDetails: [],
  getTeamTimlineResp: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getPublishTalentDataSucc(state, action) {
      state.isLoading = false;
      state.publishTeamDash = action.payload;
    },
    savePublishTalentSucc(state, action) {
      state.isLoading = false;
      state.savePublishTeamPayment = action.payload;
    },
    sendPublishTeamMailSucc(state, action) {
      state.isLoading = false;
      state.sendPublishTeamMail = action.payload;
    },
    getPublishTalentPaymentDataSucc(state, action) {
      state.isLoading = false;
      state.publishTeamPay = action.payload;
    },
    getPublishTalentListDataSucc(state, action) {
      state.isLoading = false;
      state.publishTeamList = action.payload;
    },
    getPublishTalentPaymentSetingDataSucc(state, action) {
      state.isLoading = false;
      state.publishTeamSetting = action.payload;
    },
    savePublishTeamPaySetSucc(state, action) {
      state.isLoading = false;
      state.publishTeamPaySetting = action.payload;
    },
    getPublistManageAccountSucc(state, action) {
      state.isLoading = false;
      state.managePublistAccount = action.payload;
    },
    SaveTeamPaymentSettingDataSucc(state, action) {
      state.isLoading = false;
      state.managePaymentSettingData = action.payload;
    },
    SaveTeamPaymentModeSettingDataSucc(state, action) {
      state.isLoading = false;
      state.managePaymentModeSettingData = action.payload;
    },
    sendPublishTeamOtpDataSucc(state, action) {
      state.isLoading = false;
      state.sendPublishTeamOtpData = action.payload;
    },
    getPublistAccessDeptSucc(state, action) {
      state.isLoading = false;
      state.getPublishDeptList = action.payload;
    },
    getTeamTimlineDataSucc(state, action) {
      state.isLoading = false;
      state.getTeamTimlineResp = action.payload;
    },
    getRemunerationProjDeptSucc(state, action) {
      state.isLoading = false;
      state.getRemunerationProjDeptList = action.payload;
    },
    getTeamRemunerationSucc(state, action) {
      state.isLoading = false;
      state.getTeamRemunerationList = action.payload;
    },
    getTalentUserSucc(state, action) {
      state.isLoading = false;
      state.getTalentUserResp = action.payload;
    },
    saveRemunerationDataSucc(state, action) {
      state.isLoading = false;
      state.saveRemunerationResp = action.payload;
    },
    getPaymentHistoryDataSucc(state, action) {
      state.isLoading = false;
      state.getPaymentHistoryResp = action.payload;
    },
    getRemunerationInvDataSucc(state, action) {
      state.isLoading = false;
      state.getRemunerationInvResp = action.payload;
    },
    getPublistTeamSetSucc(state, action) {
      state.isLoading = false;
      state.getPublishTeamSetting = action.payload;
    },
    saveAccessTeamDeptSucc(state, action) {
      state.isLoading = false;
      state.saveAccessTeamDept = action.payload;
    },
    verifyPublishTeamSucc(state, action) {
      state.isLoading = false;
      state.verifyPublish = action.payload;
    },
    getTalentDetailsSucc(state, action) {
      state.isLoading = false;
      state.talentDetails = action.payload;
    },
    deleteRoleSucc(state, action) {
      state.isLoading = false;
      state.deleteRole = action.payload;
    }
    // CREATE EVENT
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

// export function getPublishTalentData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     const tokenVal = window.localStorage.getItem("accessToken")
//     try {
//       const response = await axios.post('/get_publish_team_member/',data);
//       dispatch(slice.actions.getPublishTalentDataSucc(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// } 

export function getPublishTalentData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_publish_team_member/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPublishTalentDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getTalentDetailsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_talent_details/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getTalentDetailsSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function getPublishTalentListData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     const tokenVal = window.localStorage.getItem("accessToken")
//     try {
//       const response = await axios.post('/get_project_team/',data);
//       dispatch(slice.actions.getPublishTalentListDataSucc(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getPublishTalentListData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_project_team/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPublishTalentListDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function getPublishTalentPaymentData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_publish_team_payment/', data);
      dispatch(slice.actions.getPublishTalentPaymentDataSucc(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getPublishTalentPaymentSettingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_publish_team_payment_settings/', data);
      dispatch(slice.actions.getPublishTalentPaymentSetingDataSucc(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function ClearGetPublishTalentPaymentSettingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPublishTalentPaymentSetingDataSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function SavePublishPaymentData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_publish_team_payment/', data);
      dispatch(slice.actions.savePublishTalentSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function SendPublishTeamMail(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const Talent_Unique_Key = process.env.REACT_APP_UNIQUE_TALENT_KEY;
//       const config = {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           //   'Authorization': `Bearer ${data.token}`,
//         },
//         body: JSON.stringify(data)
//         // body: JSON.stringify({
//         //   "unique_key": Talent_Unique_Key,
//         //   "talent_enc_id": data.talent_enc_id
//         // })
//       }

//       if (process.env.NODE_ENV !== "Production") {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}publish_team_send_mail/`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.sendPublishTeamMailSucc(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//         console.log(data1, '564564575', fetchResponse)
//       } else {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}publish_team_send_mail/`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.sendPublishTeamMailSucc(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//       }

//       // const response = await axios.post('/publish_team_send_mail/', data);
//       // dispatch(slice.actions.sendPublishTeamMailSucc(response.data));

//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


export function SendPublishTeamMail(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }

      const Talent_Profile_view_URL = process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL
        : process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;

      const fetchResponse = await fetch(`${Talent_Profile_view_URL}publish_team_send_mail/`, config);
      const data1 = await fetchResponse.json();

      if (data1 && data1.status === 200) {
        dispatch(slice.actions.sendPublishTeamMailSucc(data1));
      } else {
        dispatch(slice.actions.hasError(data1));
      }
    } catch (error) {
      const errorMessage = error?.status ? error : { status: "502", message: "Something went wrong" };
      dispatch(slice.actions.hasError(errorMessage));
    }

  };
}



export function verifyPublishTeamOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/verify_publish_team_otp/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.verifyPublishTeamSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function deleteRoleData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_role_data/', data);
      dispatch(slice.actions.deleteRoleSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SavePublishTeamPaymentSetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_publish_team_payment_settings/', data);
      dispatch(slice.actions.savePublishTeamPaySetSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveTeamPaymentSettingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_publish_team_payment_settings/', data);
      dispatch(slice.actions.SaveTeamPaymentSettingDataSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveTeamPaymentModeSettingData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_publish_team_payment_settings/', data);
      dispatch(slice.actions.SaveTeamPaymentModeSettingDataSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublistManageAccountData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_publish_manage_account/', data);
      dispatch(slice.actions.getPublistManageAccountSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getRemunerationProjDeptData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/get_remuneration_project_department/',data);
//       dispatch(slice.actions.getRemunerationProjDeptSucc(response.data));

//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getRemunerationProjDeptData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_remuneration_project_department/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getRemunerationProjDeptSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getTeamRemunerationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_remuneration/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getTeamRemunerationSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// export function getTalentUserDetails(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const Talent_Unique_Key = process.env.REACT_APP_UNIQUE_TALENT_KEY;
//       const config = {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           //   'Authorization': `Bearer ${data.token}`,
//         },
//         body: JSON.stringify({
//           "unique_key": Talent_Unique_Key,
//           "talent_enc_id": data.talent_enc_id
//         })
//       }

//       if (process.env.NODE_ENV !== "Production") {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}get_talent_details/`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.getTalentUserSucc(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//         console.log(data1, '564564575', fetchResponse)
//       } else {
//         const Talent_Profile_view_URL = process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;
//         const fetchResponse = await fetch(`${Talent_Profile_view_URL}get_talent_details/`, config);
//         const data1 = await fetchResponse.json();
//         if (data1 && data1.status === 200) {
//           dispatch(slice.actions.getTalentUserSucc(data1));
//         } else {
//           dispatch(slice.actions.hasError(data1))
//         }
//       }
//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     }
//   };
// }




export function getTalentUserDetails(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const Talent_Unique_Key = process.env.REACT_APP_UNIQUE_TALENT_KEY;
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "unique_key": Talent_Unique_Key,
          "talent_enc_id": data.talent_enc_id
        })
      };

      const Talent_Profile_view_URL = process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL
        : process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE;

      console.log(Talent_Profile_view_URL, 'Talent_Profile_view_URL', process.env.REACT_APP_NODE_ENV === 'development', process.env.REACT_APP_NODE_ENV,);
      console.log(process.env.REACT_APP_HOST_API_KEY_TALENT_LOCAL, 'Talent_Profile_view_URL 2', process.env.REACT_APP_HOST_API_KEY_TALENT_LIVE);

      const fetchResponse = await fetch(`${Talent_Profile_view_URL}get_talent_details/`, config);
      const data1 = await fetchResponse.json();

      if (data1 && data1.status === 200) {
        dispatch(slice.actions.getTalentUserSucc(data1));
      } else {
        dispatch(slice.actions.hasError(data1));
      }
    } catch (error) {
      const errorMessage = error?.status ? error : { status: "502", message: "Something went wrong" };
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}



export function saveRemunerationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_remuneration_payment/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveRemunerationDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getPaymentHistory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_project_inovice/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPaymentHistoryDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getRemunerationInvoices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_department_invoices/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getRemunerationInvDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
// export function saveRemunerationData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/save_remuneration_payment/',data);
//       dispatch(slice.actions.saveRemunerationDataSucc(response.data));

//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function sendPublishTeamOtpData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_publish_team_otp/', data);
      dispatch(slice.actions.sendPublishTeamOtpDataSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublistAccessDeptData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_access_team_departments/', data);
      dispatch(slice.actions.getPublistAccessDeptSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeamTimlineData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_projects_timeline/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getTeamTimlineDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function saveAccessTeamDeptData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/access_team_department/', data);
      dispatch(slice.actions.saveAccessTeamDeptSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublistTeamSetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_team_setting/', data);
      dispatch(slice.actions.getPublistTeamSetSucc(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearsendPublishTeamOtpData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sendPublishTeamOtpDataSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleardeleteRoleSucc(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteRoleSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearsaveRemunerationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveRemunerationDataSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function cleargetPaymentHistoryData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPaymentHistoryDataSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSavePaymentData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.savePublishTalentSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSendMailSuccessData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sendPublishTeamMailSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export function ClearRemunerationProjDeptData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getRemunerationProjDeptSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearTeamRemunerationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTeamRemunerationSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSaveTeamPaymentSetting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SaveTeamPaymentSettingDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSaveTeamPaymentModeSetting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SaveTeamPaymentModeSettingDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearRemunerationInvoice(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getRemunerationInvDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearTalentUser(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTalentUserSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearTeamTimline(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTeamTimlineDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearPublistManageAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPublistManageAccountSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearverifyPublishTeam() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyPublishTeamSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearHasError() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}