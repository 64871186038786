import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

//---------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  saveProjectMember: [],
  getProjectMembers: [],
  GetProjectResp: [],
  getProjectRenumMemberResp: [],
  getProjectMemberResp: [],
  editProjectMember: [],
  getProjectConnectResp:[],
  getProjectNewsUpdate: [],
  saveNewsUpdatedata: [],
  saveProjectResp: [],
  updateNewsUpdatedata: [],
  DeleteNewsUpdatedata: [],
};

const slice = createSlice({
  name: 'Create Project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    createProjectMemberSucc(state, action) {
      state.isLoading = false;
      state.saveProjectMember = action.payload;
    },

    // GET EVENTS
    ProjectDetailsSucc(state, action) {
      state.isLoading = false;
      state.GetProjectResp = action.payload;
    },

    getProjectMemberSucc(state, action) {
      state.isLoading = false;
      state.getProjectMembers = action.payload;
    },

    getProjectSucc(state, action) {
      state.isLoading = false;
      state.getProjectMemberResp = action.payload;
    },

    getProjectConnectSucc(state, action) {
      state.isLoading = false;
      state.getProjectConnectResp = action.payload;
    },


    getProjectRenumSucc(state, action) {
      state.isLoading = false;
      state.getProjectRenumMemberResp = action.payload;
    },


    editProjectSucc(state, action) {
      state.isLoading = false;
      state.editProjectMember = action.payload;
    },

    createProjectSucc(state, action) {
      state.isLoading = false;
      state.saveProjectResp = action.payload;
    },

    saveNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.saveNewsUpdatedata = action.payload;
    },

    UpdateNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.updateNewsUpdatedata = action.payload;
    },

    DeleteNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.DeleteNewsUpdatedata = action.payload;
    },

    getProjectNewsUpdateSucc(state, action) {
      state.isLoading = false;
      state.getProjectNewsUpdate = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

//-------------------------------------------------------------------

export function createProjectMember(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_team_member/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createProjectMemberSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

// export function getProjectMember() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         '/get_projects/',
//         JSON.stringify({
//           project_enc_id: '',
//         }),
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//           },
//         }
//       );
//       console.log('getprojectresponse', response);
//       if (response && response.data && response.data.status === 200) {
//         dispatch(slice.actions.getProjectMemberSucc(response.data));
//       } else {
//         dispatch(slice.actions.hasError(response.data));
//       }
//     } catch (error) {
//       if (error && error.status !== '' && error.status !== undefined) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
//       }
//     }
//   };
// }

// export function getProjectDetails(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/get_projects/', data);
//       console.log(response, 'response43');
//       if (response && response.data && response.data.status === 200) {
//         dispatch(slice.actions.ProjectDetailsSucc(response.data));
//       } else {
//         dispatch(slice.actions.hasError(response.data));
//       }
//     } catch (error) {
//       if (error && error.status !== '' && error.status !== undefined) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
//       }
//     }
//   };
// }

export function getProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_projects/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}



export function getRemuneration(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_remuneration_dashboard/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectRenumSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function editProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/edit_project', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.editProjectSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function createProjectDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await axios.post('/create_project/', data);
    dispatch(slice.actions.createProjectSucc(response));
    // try {
    //   // if (response) {

    //   // } else {
    //   //   dispatch(slice.actions.hasError(response.data));
    //   // }
    // } catch (error) {
    //   if (error && error.status !== '' && error.status !== undefined) {
    //     dispatch(slice.actions.hasError(error));
    //   } else {
    //     dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
    //   }
    // }
  };
}

export function saveNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_project_news_update/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.saveNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function UpdateNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employer_project_news_update/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UpdateNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DeleteNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_project_newsupdate/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProjectNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_projects_newsupdate/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectNewsUpdateSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCreateTeam(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveNewsUpdateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearUpdateNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UpdateNewsUpdateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearDeleteNewsUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteNewsUpdateSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CleareditProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.editProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearAddProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createProjectSucc([]));
      dispatch(slice.actions.createProjectMemberSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProjectDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ClearProjectConnect(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectConnectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function ClearProjectRenumDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectRenumSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getProjectsConnect(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_project_users/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProjectConnectSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}



