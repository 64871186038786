import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// import logo from '../assets/'
import Logos from '../images/TK-LOGO.png';

// ----------------------------------------------------------------------

LoginLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LoginLogo({ disabledLink = false, sx }) {

  const logo = (
    <Box sx={{ width: 200, ...sx }}>
      <img src={Logos} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <a href="https://talentkind.com/" className='logiLoginHolder'>{logo}</a>;
}
