// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ updateProfile, ...other }) {
  const { user } = useAuth();

  console.log(updateProfile, 'updateProfile');

  return (
    <Avatar
      src={updateProfile}
      alt={user?.displayName}
      className='NavbarAvatar rightProfileAvatar'
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
