// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [



  // MANAGEMENT
  // ----------------------------------------------------------------------

  {
    // subheader: 'management',
    items: [
      // MANAGEMENT : USER
      { title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard },
      { title: 'Profile', path: PATH_DASHBOARD.general.profile },
      { title: 'Search', path: PATH_DASHBOARD.talentSearch },
      {
        title: 'Job and Project Management',
        path: PATH_DASHBOARD.jobs.root,
        icon: ICONS.user,
        children: [
          { title: 'Jobs Posting', path: PATH_DASHBOARD.jobs.postings },
          { title: 'Create And Manage Project', path: PATH_DASHBOARD.jobs.create },
        ]
      },
      {
        title: 'Connect',
        path: PATH_DASHBOARD.connectnetwork.root,
        icon: ICONS.user,
        children: [
          { title: 'Followers', path: PATH_DASHBOARD.connectnetwork.followers },
          { title: 'Following', path: PATH_DASHBOARD.connectnetwork.following },
          // { title: 'Network', path: PATH_DASHBOARD.connectnetwork.network },
        ],
      },
      { title: 'Calendar', path: PATH_DASHBOARD.calendar },
      { title: 'Inbox', path: PATH_DASHBOARD.inBox },
      // { title: 'Mail Box', path: PATH_DASHBOARD.inBox },
      // { title: 'Reports', path: PATH_DASHBOARD.networking },
      {
        title: 'Reports',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.user,
        children: [
          { title: 'Activity', path: PATH_DASHBOARD.reports.activity },
        ],
      },
      { title: 'Settings and Preferences', path: PATH_DASHBOARD.setting },
      { title: 'Subscription', path: PATH_DASHBOARD.subscription },
      { title: 'Projects', path: PATH_DASHBOARD.project.projects },
    ],
  }

];

export default navConfig;
