import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';


const initialState = {
  isLoading: false,
  error: null,
  RespContactData: [],
  addRespContactData:[],
  addRespContactAddress:[],
  deleteContactResp:[],
  isOpenModal: false, 
  updateContactResponse: [],
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    collectContactSucc(state, action) {
      state.isLoading = false;
      state.RespContactData = action.payload;
    },
    addcollectContactSucc(state, action){
      state.isLoading = false;
      state.addRespContactData = action.payload;
    },
    addcollectContactAddSucc(state, action){
      state.isLoading = false;
      state.addRespContactAddress = action.payload;
    },
    deleteContactSuccess(state, action) {
      state.isLoading = false;
      state.deleteContactResp = action.payload; 
    },
    updateContactSucc(state, action) {
      state.isLoading = false;
      state.updateContactResponse = action.payload;
    },
   },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;



  export function getContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/get_my_contact/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.collectContactSucc(response.data)); 
          }else{
          dispatch(slice.actions.hasError(response.data)) 
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function ClearGetContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.collectContactSucc([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
  }

  export function addContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/create_my_contact/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addcollectContactSucc(response.data)); 

          }else{
          dispatch(slice.actions.hasError(response.data)) 
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function UpdatedContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/create_my_contact/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.updateContactSucc(response.data)); 

          }else{
          dispatch(slice.actions.hasError(response.data)) 
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function ClearUpdatedContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.updateContactSucc([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
  }


  export function ClearaddContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addcollectContactSucc([]));
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
  }




  export function deleteContacts(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/delete_my_contact/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.deleteContactSuccess(response.data)); 
          }else{
          dispatch(slice.actions.hasError(response.data)) 
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function CleardeleteData(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.deleteContactSuccess([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
  }
  export function clearHasError(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.hasError([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  

  export function addContactsAddress(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/contact_address/',data);
        if(response && response.data && response.data.status === 200 ){
          dispatch(slice.actions.addcollectContactAddSucc(response.data)); 

          }else{
          dispatch(slice.actions.hasError(response.data)) 
        }
      } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        }
      }
    };
  }

  export function ClearaddContactsAddress(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.addcollectContactAddSucc([]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
  }